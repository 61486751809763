import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";

export default function GoogleSearchAutocomplete({ address,  setCoordinates, setAddress}) {

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latlng = await getLatLng(results[0]);
        setAddress(value);
        setCoordinates(latlng);
    };

    return(
        <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}>
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => 
            <div>
                <input {...getInputProps({className: 'text-input__input'})} id="venue_address"/>
                <label htmlFor="venue_address" className="places-autocomplete__label input-field__label active">Address</label>
                <div className={suggestions.length ? 'places-autocomplete__options-block' : ''}>

                    {suggestions.map((suggestion) => {
                        const style = {
                            backgroundColor: suggestion.active ? "#DADDE3" : "#fff"
                        }

                        return (
                            <div key={suggestion.index}
                                {...getSuggestionItemProps(suggestion, { style })} 
                                className="places-autocomplete__option">
                                    {suggestion.description}
                            </div>)})}
                    
                </div>
            </div>}
        </PlacesAutocomplete>
    )
}
