import moment from 'moment';
import { cleanup, isPartialEqual } from './utils';

const DATE_FORMAT = 'DD/MM/YYYY';
const MATCH_COMPARE_KEYS = [
  'start_date',
  'start_time',
  'team_a_id',
  'team_b_id',
  'course_id',
];

export const additionIncomeDTO = (venue_id, date, amount) => ({
  operation: {
    owner_id: venue_id,
    op_type: 'addition',
    amount,
    date,
  },
});

export const teamsExportDTO = (includedSeasons) => ({
  includes_ids: includedSeasons.map((item) => item.id),
});

export const leagueEnrolmentDTO = (id, fee) => ({
  id,
  fee,
});

export const leagueDTO = (league, team_ids, once_off_fees_attributes, fees_data) => ({
  sport_id: league.sport_id,
  venue_id: league.venue_id,
  division_id: league.division_id,
  grade_id: league.grade_id,
  season_id: league.season_id,
  fee: league.fee,
  team_ids,
  day: league.day,
  once_off_fees_attributes
});

export const teamDTO = (team) => ({
  title: team.title,
  user_id: team.user_id,
  sport_id: team.sport_id,
  venue_id: team.venue_id,
  day: team.day,
});

export const playerObjectDTO = (
  first_name,
  last_name,
  email,
  phone,
) => ({
  player: {
    first_name,
    last_name,
    phone,
    email,
  },
});

export const playerDTO = (player) => ({
  first_name: player.first_name,
  last_name: player.last_name,
  phone: player.phone,
  email: player.email,
});

export const onceOffFeesDTO = (fee) => ({
  amount: fee.amount,
  description: fee.description,
  due_date: fee.due_date
});

export const newOnceOffFeesDTO = (fee) => ({
  amount: fee.amount,
  description: fee.description,
  due_date: fee.due_date,
  team_ids: fee.allteam_ids
});

export const fixtureDTO = (fixture) => ({
  start_date: fixture.start_date.format('YYYY-MM-DD'),
  round_number: fixture.round_number,
  non_playing_dates: fixture.non_playing_dates
    .map((d) => d.format(DATE_FORMAT))
    .join(','),
  publish: fixture.publish,
  team_positions: fixture.team_positions || [],
});

export const finalFixtureDTO = (fixture) => ({
  ...fixtureDTO(fixture),
  format: fixture.format,
});

export const fixtureMap = (fixture) => ({
  ...fixture,
  start_date: moment(fixture.start_date),
  non_playing_dates:
    (fixture.non_playing_dates
      && fixture.non_playing_dates
        .split(',')
        .map((d) => moment(d, DATE_FORMAT)))
    || [],
});

export const timeSlotDTO = (slot) => ({
  course_id: slot.court,
  start_time: moment(slot.hours, 'h Am')
    .add(slot.minutes, 'm')
    .format('HH:mm'),
});

export const operationDTO = (data) => ({
  operation: {
    owner_id: data.owner_id,
    op_type: data.op_type.toLowerCase(),
    amount: data.amount,
    description: data.description,
    date: data.date.format(DATE_FORMAT),
  },
});

export const editFixtureDTO = (data) => {
  const updatedMatches = [];

  Object.keys(data.rounds).forEach((round) => data.rounds[round].forEach((match) => {
      if (match && !!match.updated) {
        const clean = cleanup({
          course_id: match.course_id,
          sport_id: match.sport_id,
          round: match.round,
          start_date: match.start_date,
          start_time: match.start_time,
          id: match.id > 0 ? match.id : null,
        });
        updatedMatches.push({
          ...clean,
          team_a_id: match.team_a_id,
          team_b_id: match.team_b_id,
        });
      }
    }),
  );
  return {
    fixture: {
      publish: data.publish ? 1 : 0,
      matches_attributes: updatedMatches,
    },
  };
};

export const replaceTeamDTO = ({
  from_round,
  team_in_id,
  team_out_id,
  newSlot,
  wizard,
}) => {
  const data = { from_round };

  if (team_in_id) {
    data.team_in_id = team_in_id;
  }
  if (team_out_id) {
    data.team_out_id = team_out_id;
  }
  if (newSlot) {
    data.timeslot_id = newSlot.id;
  }

  return { [wizard]: data };
};

export const venueDTO = (
  id,
  title,
  facility,
  address,
  latitude,
  longitude,
  icon,
  banner,
  deleted_at,
  courses_attributes,
  venue_information
) => {
  const hsh = {
    id,
    title: title.trim(),
    facility: facility.trim(),
    address,
    latitude,
    longitude,
    icon,
    banner,
    deleted_at,
    courses_attributes,
    venue_information
  };
  return clean(hsh);
};

export const courseDTO = (course, index) => {
  const id = course.id < 0 ? null : course.id;
  const hsh = {
    id: id || null,
    title: course.title,
    position: (course.position = index),
    holes_params: course.holes,
    status: course.status ? '1' : '0',
  };
  return clean(hsh);
};

function clean(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}
