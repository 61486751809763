import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Button from '../shared/Button';
import { Col, Row } from '../shared/Grid';
import TextInput from '../shared/TextInput';
import DateInput from '../shared/DateInput';
import Select from '../shared/Select';
import { updateInputs } from '../../lib/materialHelper';

const TYPES = [
  { title: 'Credit', id: 'addition' },
  { title: 'Fee', id: 'deduction' },
];

export default class CreateOperation extends React.Component {
  static propTypes = {
    onCreate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    styleClassName: PropTypes.string,
    currencySymbol: PropTypes.string.isRequired,
  };

  static defaultProps = {
    styleClassName: 'card card_marged-top card_padded',
  };

  state = {
    date: moment(this.props.date),
    op_type: TYPES[0].id,
    amount: 0,
    description: '',
  };

  componentDidMount() {
    updateInputs();
  }

  render() {
    const {
      onCreate,
      onCancel,
      styleClassName,
      currencySymbol,
    } = this.props;
    const { date, op_type, amount, description } = this.state;

    return (
      <form className={styleClassName}>
        <Row classMod="u-mb-2">
          <Col l="6" mod="col-operation">
            <DateInput
              title="Date"
              mod="text-input__input_small"
              id="new-operation-date"
              value={date}
              onChange={(date) => this.setState({ date })}
            />
          </Col>
          <Col l="6" mod="col-operation">
            <TextInput
              title="Description"
              id="new-operation-description"
              value={description}
              onChange={(description) => this.setState({ description })
              }
            />
          </Col>
        </Row>
        <Row classMod="u-mb-2">
          <Col l="12" mod="col-operation">
            <Select
              title="Type"
              mod="select-dropdown_small"
              fieldMod="input-field_small"
              required
              id="new-operation-type"
              items={TYPES}
              value={op_type}
              onSelect={(op_type) => this.setState({ op_type })}
            />
          </Col>
        </Row>
        <Row classMod="u-mb-2">
          <Col l="12" mod="col-operation">
            <TextInput
              title="Amount"
              mod="text-input__input_small"
              measure={currencySymbol} // add curency symbol from company
              id="new-operation-amount"
              type="number"
              value={amount}
              onChange={(amount) => this.setState({ amount })}
            />
          </Col>
        </Row>
        <Row classMod="u-mb-2">
          <Col l="12">
            <div className="d-flex">
              <Button
                mod="Button--primary u-mr-1"
                title="Create"
                id="create-operation-btn"
                onClick={() => onCreate(this.state)}
              />
              <Button
                title="Cancel"
                id="cancel-operation-btn"
                onClick={onCancel}
              />
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}
