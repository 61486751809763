import React, { useState } from "react";
import Button from "../shared/Button";


export default function ConfirmOnceOffFees({ extraTeams, teams, teamIds, extraTeamIds, onceOffFees, confirmCallback, cancelCallback }) {

    const transformated_once_off_fees = onceOffFees.filter(item => {

        if (!item.oldteam_ids && !item.team_ids) {
            item.team_ids = extraTeamIds
            item.oldteam_ids = teamIds
            return item
        } 
        else if(!item.oldteam_ids && item.team_ids && item.id > 0) {
            item.oldteam_ids = teamIds.filter(team => !item.team_ids.includes(team))
            item.team_ids = item.team_ids.concat(extraTeamIds)
            return item
        } else {
            return item
        }
    });

    const [onceoffFees, setOnceoffFees] = useState(transformated_once_off_fees);

    const isChecked = (team, fee) => {
        const index = onceoffFees.findIndex(item => item.id === fee);
        let checked = false;
        if (onceoffFees[index].oldteam_ids) {
            checked = onceoffFees[index].oldteam_ids.includes(team) || onceoffFees[index].team_ids.includes(team);
            console.log('checked: ', checked)
        } else {
            checked = onceoffFees[index].team_ids.includes(team);
        };
        return checked
    };

    const isDisabled = (fee, teamId) => {
        if (fee.oldteam_ids) {
            return fee.oldteam_ids.includes(teamId)
        } else {
            console.log('!!fee.oldteam_ids: ', !!fee.oldteam_ids)
            return fee.oldteam_ids ? true : false
        }
    };

    const CheckboxAction = ( onceOffID, teamID ) => {

        let newOnceofffees = onceoffFees

        if (isChecked(teamID, onceOffID)) {
            newOnceofffees =  newOnceofffees.filter(onceoff => {
                if (onceoff.id === onceOffID) {
                    onceoff.team_ids = onceoff.team_ids.filter(item => item !== teamID)
                } 
                return onceoff
            });
            setOnceoffFees(newOnceofffees)
        } else {
            newOnceofffees = newOnceofffees.filter(onceoff => {
                if (onceoff.id === onceOffID) {
                   onceoff.team_ids.push(teamID)
                }  
                return onceoff
            });
            setOnceoffFees(newOnceofffees)
        }
    };

    return (
        <div className={`PaymentModal u-element-center u-fixed u-p-3 ConfirmOnceOffFees-modal`}>
                
            <h1 className="center-align u-font-size-20 u-weight-700 u-mb-1">Confirming</h1>

            <div className="ConfirmOnceOffFees-scroll-block">

                {extraTeams.length && extraTeams.map(team => {
                    return (
                        <div key={team.id} className="d-flex u-pb-1 ConfirmOnceOffFees-team-row">
                            <div className="u-mr-1 col-3">{team.title}</div>
                            <div className="d-flex ConfirmOnceOffFees-fee-row">
                                {onceoffFees.length && onceoffFees.map(item => {
                                    const { id, description, amount } = item;
                                    return (
                                        <div key={id} className={`d-flex u-pl-1 u-ml-2 u-mt-1 ConfirmOnceOffFees-fee`}>
                                            <input className='OnceOffFeesModal-input u-pointer' 
                                                    type="checkbox" 
                                                    id="OnceOffFeesModal-checkbox"
                                                    value={id}
                                                    checked={isChecked(team.id, id)}
                                                    onChange={(e) => CheckboxAction(id, team.id)}/>
                                            <div className="u-pl-1">{`${description} ($${amount})`}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            
                        </div>
                    )
                })}

                {teams.length && teams.map(team => {
                    return (
                        <div key={team.id} className="d-flex u-pb-1 ConfirmOnceOffFees-team-row">
                            <div className="u-mr-1 col-3">{team.title}</div>
                            <div className="d-flex ConfirmOnceOffFees-fee-row">
                                {onceoffFees.length && onceoffFees.map(item => {
                                    const { id, description, amount } = item;
                                    return (
                                        <div key={id} className={ isDisabled(item, team.id) ? 'd-flex u-pl-1 u-ml-2 u-mt-1 ConfirmOnceOffFees-fee-disabled' : 'd-flex u-pl-1 u-ml-2 u-mt-1 ConfirmOnceOffFees-fee'}>
                                            <input className={isDisabled(item, team.id) ? 'ConfirmOnceOffFees-input-disabled OnceOffFeesModal-input' : 'OnceOffFeesModal-input u-pointer'} 
                                                type="checkbox" 
                                                id="OnceOffFeesModal-checkbox"
                                                value={id}
                                                disabled={isDisabled(item, team.id)}
                                                checked={isChecked(team.id, id)}
                                                onChange={(e) => CheckboxAction(id, team.id)}/>
                                            <div className={isDisabled(item, team.id) ? 'u-pl-1 ConfirmOnceOffFees-text-disabled' : 'u-pl-1'}>{`${description} ($${amount})`}</div>
                                        </div>)
                                })}
                            </div>  
                        </div>)
                })}
            </div>

            <div className="d-flex u-mt-3 justify-content-center">
                <Button
                    mod="Button--primary Button--medium ManageListing-button--large"
                    title="Confirm"
                    id="confirm-transfer"
                    onClick={() => confirmCallback(onceoffFees)}
                />
                <Button
                    mod="Button Button--medium ManageListing-button--large u-ml-2"
                    title="Cancel"
                    id="cancel-transfer"
                    onClick={() => cancelCallback()}
                />
            </div>

        </div>
    )
}
