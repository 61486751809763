import React from "react";

export default function CloseListing({ cancelCallback, confirmCallback }) {

    return (
        <div className='PaymentModal u-element-center u-fixed u-p-3'>
            <div className="center-align">
                <h5 className="u-weight-500 u-pb-2">Close listing</h5>
                <p className="u-pb-3">
                  Are you really want to close listing?
                </p>
            </div>

            <div className="d-flex justify-content-center">
                <a
                  className="Button Button--redBg u-mr-1"
                  onClick={confirmCallback}
                >Close</a>
                <a
                  className="Button Button--cancel"
                  onClick={cancelCallback}
                >Cancel</a>
            </div>
        </div>
        
    )
}
