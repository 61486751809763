import React from 'react'
import Button from "../../shared/Button";
import {editFee, editPayment, markAsPaid, refund} from "../../Payments/util/_options";
import {statuses} from "../../Payments/util/_statuses";
import moment from "moment";
import { moneyFormatterZeros } from '../../../lib/utils';

export default function MatchPaymentTabItem({invoice, actionClick}) {

    const {amount, description, due_date, status, overdue, manual} = invoice;

    const isActive = statuses[status] === 'Active';
    const isActiveOverdue = isActive && overdue;
    const canRefund = !manual && (status === 'paid' || status === 'open')

    let buttonTitle;
    let option;

    if (isActive) {
        buttonTitle = 'Pay Fee';
        option = markAsPaid;
    } else {
        buttonTitle = 'Refund';
        option = refund
    }

    return (
        <div className="schedule-page-payment-invoice-row u-mb-1">
            <div className="SchedulePaymentsTab-overdue">
                {isActiveOverdue &&
                <p className="u-color-red u-font-size-11">Due date: {moment(due_date).format('LL')}</p>}
            </div>
            <div className="d-flex justify-content-between align-items-center">

                <div className="SchedulePaymentsTab-action d-flex justify-content-between u-flex-1 align-items-center">
                    <h1 className={`SchedulePaymentsTab-amount ${isActiveOverdue ? 'u-color-red' :
                        !isActive ? 'SchedulePaymentsTab-amount--paid' : ''}`}
                    >
                        ${moneyFormatterZeros(amount)}
                    </h1>
                    
                    { manual && <i className="SchedulePaymentsTab-action far fa-pen adjust-fee-schedule-tab-icon u-pointer u-ml-1"
                           onClick={() => actionClick(invoice, statuses[status] === 'Active' ? editFee : editPayment)}
                        />
                    }
                    <span className="SchedulePaymentsTab-description u-pl-2 u-flex-1">{description}</span>
                    { manual && status === 'open' &&
                    <Button title="Pay Fee"
                            mod={'Button-static-width--100 Button--black'}
                            id="payment-action-schedule-page-button"
                            onClick={() => actionClick(invoice, markAsPaid)}/>
                    }
                    {canRefund && 
                    <Button title="Refund"
                            mod={'Button-static-width--100 Button--black'}
                            id="payment-action-schedule-page-button"
                            onClick={() => actionClick(invoice, refund)}/>
                    }
                </div>
            </div>
        </div>

    )
}
