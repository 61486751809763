import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/shared/Modal';

const calculatePlayersCount = (players) => players.reduce((acc, player) => (player.played ? ++acc : acc), 0);

const itemStyle = {
  borderBottom: '1px solid #F1F3F7',
};

const AttendantsModal = ({
  teamTitle,
  onCancel,
  players,
}) => {
  const [checkedAttendants] = useState(players);

  return (
    <Modal
      id="attendance_modal"
      show
      footer={
        <div className="d-flex">
          <a
            className="Button Button--medium Button--cancel"
            onClick={onCancel}
          >
            Cancel
          </a>
        </div>
      }
    >
      <div>
        <div
          style={{ ...itemStyle }}
          className="d-flex justify-content-between align-items-center u-pb-1 u-font-size-16"
        >
          <h5 className="u-weight-500">{teamTitle} Lineup</h5>
          <span className="u-color-emerald u-weight-500">
            {calculatePlayersCount(checkedAttendants)} Playing
          </span>
        </div>

        <div className="u-pb-2">
          {checkedAttendants.map(({ user_id, name, played }) => (
            <div
              style={{ ...itemStyle }}
              key={user_id}
              className="u-pb-1 u-pt-1 u-pr-1"
              value={user_id}
              label={name}
            >
              <span>{name}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

AttendantsModal.propTypes = {
  teamTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AttendantsModal;
