import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../shared/Button';
import TextInput from '../shared/TextInput';

export default function CourseForm({
                                        course,
                                        editMod,
                                        onCancel,
                                        onSubmit
                                        }) {

  const [courseCurrent, setCourseCurrent] = useState(course);
  const [holes, setHoles] = useState(course.holes);
  const [numberOfHoles, setNumberOfHoles] = useState(null);

  useEffect(() => {
      if (holes.length < 9) {
        createHoles(9)
        setNumberOfHoles(9)
      } else {
        setNumberOfHoles(holes.length)
      }
  }, [holes]);

  const createHoles = (number) => {
    let newArr = []
    for(let i=0; i < number; i++ ) {
        newArr.push({title: `Hole ${i+1}`, par: '', difficulty: ''})
    }
    setHoles(newArr);
  };

  const updateNumberOfHoles = (number) => {
    let newHolesArr = holes;
    if (number > numberOfHoles) {
      for(let i=numberOfHoles; i < number; i++) {
        newHolesArr.push({title: `Hole ${i+1}`, par: '', difficulty: ''})
      }
      setHoles(newHolesArr)
    } 
    if (number < numberOfHoles) {
      newHolesArr.splice(number, 9)
      setHoles(newHolesArr)
    }
    setNumberOfHoles(number)
  }

  const onFormSubmit = () => {
    onSubmit(
      courseCurrent.id,
      courseCurrent.position,
      courseCurrent.title,
      holes
    );
  };

  const updateValue = (e, valueBox, index) => {
    const value = e.target.value;
    setHoles(state => state.map((hole, i) => i === index ? {...hole, [valueBox]: value} : hole))
  };

    return (
      <div className="u-mt-2 u-p-2 bg-athens-gray">
        <div className="d-flex flex-row justify-content-between align-items-baseline">
          <span className="form-title">{editMod} course</span>
          <span
            className="table__action"
            onClick={() => onCancel()}
          >
            Cancel
          </span>
        </div>

        <div className="course-form">
          <TextInput
            validate={['required']}
            type="text"
            title="Title"
            id="course_title"
            value={courseCurrent.title}
            onChange={(value) => setCourseCurrent({...courseCurrent, title: value})}
          />
          {course.errors && course.errors.title && (
            <span className="help-block has-error">
              {course.errors.title}
            </span>
          )}

          <table className="bordered highlight u-w-40 Course_table">
            <thead>
              <tr className="u-noBorder">
                <th></th>
                <th>Par</th>
                <th>Index</th>
              </tr>
            </thead>
            <tbody>
              {holes.length >= 9 ? holes.map((hole, index) => {
              const {
                title,
                par,
                difficulty
              } = hole;
              return (
                <tr key={index} className="collection-item">
                  <td>
                    <input 
                      className='Course_hole-title'
                      type="text" 
                      value={title}
                      onChange={(e) => updateValue(e, 'title', index)}/>
                  </td>
                  <td>
                    <input 
                      className='Course_box-input'
                      value={par || ''} 
                      onChange={(event) => updateValue(event, 'par', index)}

                      type="text" /></td>
                  <td>
                    <input 
                      className='Course_box-input'
                      value={difficulty || ''} 
                      onChange={(event) => updateValue(event, 'difficulty', index)}

                      required 
                      type="text" /></td>
                </tr>
              )}): null}
            </tbody>
          </table>

          {course.errors && course.errors.holes && (
            <span className="help-block has-error">
              {course.errors.holes}
            </span>
          )}

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {holes.length > 9 ? 
                <button
                  style={{height: '20px'}}
                  className='Button Button--small u-font-size-12'
                  id='delete-nine-holes'
                  onClick={() => updateNumberOfHoles(numberOfHoles-9)}>-</button>
              : null}
              {holes.length <= 18 ?
              <button
                style={{height: '20px', marginLeft: '5px'}}
                className='Button Button--small u-font-size-12'
                id='add-nine-holes'
                onClick={() => updateNumberOfHoles(numberOfHoles+9)}>+</button>
              : null}
            </div>
            <Button
              mod="Button Button--small u-font-size-12"
              title="Save"
              id="add-course-button"
              onClick={() => onFormSubmit()}
            />
          </div>
        </div>
      </div>
    );
}

CourseForm.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    position: PropTypes.number,
    holes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        par: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        difficulty: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    ).isRequired
  }).isRequired,
  editMod: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

