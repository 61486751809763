import React from 'react';
import Background from '../../Payments/Background';
import { editTeamRegistration } from '../util/_options';

export default function WaitingListTable({
  registrationsList,
  onOptionClick,
  onDeleteTeam,
  onArrowClick
}) {

  return (
    <React.Fragment>
      <Background />
      <table className={`ManageListing-table--waitinglist highlight u-w-100`}>
        <thead>
          <tr className="u-text-left u-noBorder u-noPadiing">
            <th></th>
            <th className="text__color_blue">Team</th>
            <th className="text__color_blue">Captain</th>
            <th className="text__color_blue">
              Additional information
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {registrationsList.length
            ? registrationsList.map((team) => {
                const {
                  id,
                  email,
                  phone,
                  first_name,
                  last_name,
                  description,
                  returning_team,
                  title
                } = team;
                return (
                  <tr
                    key={id}
                    className="collection-item bordered--2 ManageListing-table-row"
                  >
                    <td className="u-pt-1 u-pb-1 ManageListing-icon--column">
                      {returning_team ? 
                        <i className="fal fa-star Icon Icon--noBorder ManageListing-icon--pencil u-mr-1"></i>
                      : null }
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-teamtitle-column">
                      {title}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1">
                      {`${first_name} ${last_name}`} <br />
                      {`+${phone}`} <br />
                      {email}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-big-column">
                      {description}
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i 
                        className="fal fa-arrow-alt-left ManageListing-icon ManageListing-icon--arrow"
                        onClick={() => onArrowClick(id)}
                      ></i>
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i
                        onClick={() => onOptionClick(editTeamRegistration, team)}
                        className="far fa-pencil ManageListing-icon ManageListing-icon--pencil"
                      ></i>
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i
                        onClick={() => onDeleteTeam(id)}
                        className="fal fa-times-circle ManageListing-icon ManageListing-icon--pencil"
                      ></i>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </React.Fragment>
  );
}
