import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import { additionIncomeDTO } from '../../lib/DTO';
import {moneyFormatterZeros} from "../../lib/utils"

export default class AdditionIncome extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    venue_id: PropTypes.number.isRequired,
    amount: PropTypes.string.isRequired,
    onAdditionalIncomeUpdate: PropTypes.func.isRequired,
  };

  state = {
    amount: parseFloat(this.props.amount).toFixed(1),
  };

  onAmountChanged = (e) => {
    this.setState({ amount: e.target.value });
  };

  changeHandling = () => {
    const { amount } = this.state;
    const { venue_id, date } = this.props;

    const additionIncome = parseFloat(amount).toFixed(1) || 0.0;

    if (additionIncome === parseFloat(this.props.amount).toFixed(1))
      return;

    if (additionIncome > 0) {
      this.updateOperation(venue_id, date, additionIncome).then(
        () => {
          this.props.onAdditionalIncomeUpdate(additionIncome);
        },
      );
    } else {
      this.destroyOperation(venue_id, date).then(() => {
        this.props.onAdditionalIncomeUpdate(0);
      });
    }
  };

  updateOperation = (venue_id, date, amount) => axios
      .put(
        `/venues/${venue_id}/operations/${date}`,
        additionIncomeDTO(venue_id, date, amount),
      )
      .then(() => {
        M.toast({
          html: 'Additional income successfully updated',
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        M.toast({ html: error.response.data, classes: 'u-bg-red' });
      });

  destroyOperation = (venue_id, date) => axios
      .delete(`/venues/${venue_id}/operations/${date}.json`)
      .then(() => {
        M.toast({
          html: 'Additional income successfully deleted',
          classes: 'u-bg-emerald',
        });
      });

  render() {
    const { amount } = this.state;

    return (
      <div className="u-pr-3 u-pb-2 d-flex justify-content-flex-end align-items-center">
        <label
          className="u-pr-2 u-font-size-12"
          htmlFor="additional-income"
        >
          Additional income
        </label>

        <input
          className="browser-default schedule__input-field schedule__input-field--totals"
          type="number"
          id="additional-income"
          value={amount}
          onBlur={this.changeHandling}
          onChange={this.onAmountChanged}
        />
      </div>
    );
  }
}
