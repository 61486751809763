import PropTypes from 'prop-types';
import React from 'react';

export default class TeamsList extends React.Component {
  static propTypes = {
    teams: PropTypes.array.isRequired,
    teamIds: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  state = {
    ignore: this.props.teamIds,
  };

  render() {
    const { teams, onRemove } = this.props;

    return teams.length ? (
      <div>
        {teams.map((team, index) => (
          <div className="teams-list__row" key={index}>
            <div className="teams-list__team">
              {team.title} ({team.captain})
            </div>
            <div
              className="teams-list__remove"
              onClick={() => onRemove(team)}
            >
              <i className="far fa-times u-font-size-17" />
            </div>
          </div>
        ))}
      </div>
    ) : (
      <span className="teams-list__no-teams">
        There are no teams in the league yet
      </span>
    );
  }
}
