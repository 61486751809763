import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import { findIndex } from 'lodash';
import { Col, Row } from '../shared/Grid';
import AdditionIncome from './AdditionIncome';
import MatchItem from './MatchItem';
import Spinner from '../shared/Spinner';
import { validateMatchesCompleteness } from '../../helpers/ratingHelper';
import NotesRichEditor from './NotesRichEditor';
import ManagerNotesEditor from './ManagerNotesEditor';
import {
  getErrorTextFromObject,
  componentsJoin,
} from '../../helpers/utils';
import {
  isMatchUnplayed,
  isMatchCompleted,
} from '../../helpers/matchValidations';
import {moneyFormatterZeros} from "../../lib/utils"

export default class ScheduleForm extends React.Component {
  static propTypes = {
    is_admin: PropTypes.bool.isRequired,
    is_synced: PropTypes.bool.isRequired,
    completed_night: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
    venue_id: PropTypes.number.isRequired,
    additional_income: PropTypes.string.isRequired,
    matches: PropTypes.arrayOf(PropTypes.object).isRequired,
    bye_teams: PropTypes.array.isRequired,
    admin_note: PropTypes.shape({
      date: PropTypes.string.isRequired,
      venue_id: PropTypes.number.isRequired,
      message: PropTypes.string,
    }).isRequired,
    manager_note: PropTypes.shape({
      date: PropTypes.string.isRequired,
      venue_id: PropTypes.number.isRequired,
      message: PropTypes.string,
    }).isRequired,
  };

  state = {
    date: this.props.date,
    matches: this.props.matches,
    additionalIncome: this.props.additional_income,
    isSynced: this.props.is_synced,
    isCompletedNight: this.props.completed_night,
    isLoading: false,
    timers: {},
    currencySymbol: '',
    isAttendantsDisabled: true,
  };

  componentDidMount() {
    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          isCompletedNightVisible: response.data.f_complete_night,
          isLeagueNotesVisible: response.data.f_manager_notes,
          currencySymbol: response.data.currency_symbol,
          isAttendantsDisabled: !response.data.f_player_attendance,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  }

  onAdditionalIncomeUpdate = (venue_id, date) => (value) => {
      axios.put(`/api/venues/${venue_id}/additional_income`,
          {invoice: {date: date, amount: value}})
          .then(() => this.setState({additionalIncome: String(value)}))
          .catch((error) => {
              M.toast({
                  html: `Failed to update an additional income: <br>${getErrorTextFromObject(
                      error,
                  )}`,
                  classes: 'u-bg-red',
              });
          })
  };

  nullifyResults = (match) => {
    const { date, matches } = this.state;
    return axios
      .put(
        `/matches/${match.id}.json`,
        { match: { id: match.id, score_a: null, score_b: null } },
        { params: { date } },
      )
      .then((response) => {
        this.updateMatches(response.data);

        this.cleanupTimer(match);
      })
      .catch((error) => {
        M.toast({
          html: `Failed to update a match: <br>${getErrorTextFromObject(
            error,
          )}`,
          classes: 'u-bg-red',
        });
      });
  };

  cleanupTimer = (match) => {
    const { timers } = this.state;
    const timer = timers[match.id];

    delete timers[match.id];

    clearTimeout(timer);
    this.setState({ timers });
  };

  updateMatches = (match) => {
    const { matches } = this.state;
    const index = findIndex(matches, { id: match.id });
    const updatedMatches = [
        ...matches.slice(0, index),
        match,
        ...matches.slice(index + 1)
    ]
    this.setState({ matches: updatedMatches });
  };

  onMatchUpdate = ({ match, isNeedCompleteNight }) => {
    const { timers } = this.state;
    this.updateMatches(match);

    if (timers[match.id]) {
      this.cleanupTimer(match);
    }

    if (isMatchUnplayed(match) || isMatchCompleted(match)) {
      this.cleanupTimer(match);
      this.sendReport(isNeedCompleteNight);
      this.setState({ isSynced: true });
    } else {
      const timer = setTimeout(() => {
        M.toast({
          html: 'Score required for both teams. Please re-enter.',
          classes: 'u-bg-red',
        });
        this.nullifyResults(match);
      }, 6000);

      this.setState((prevState) => ({
        timers: {
          ...prevState.timers,
          [match.id]: timer,
        },
      }));
    }
  };

  sendReport = (isNeedCompleteNight) => {
    const { isCompletedNight, date } = this.state;
    const { venue_id } = this.props;

    if (isNeedCompleteNight && isCompletedNight) {
      this.setState({ isCompletedNight: false });

      axios
        .post('/api/reports.json', {
          report: { date, venue_id, completed_night: false },
        })
        .then(() => {
          M.toast({
            html: 'Night to be completed again due to changed values',
            classes: 'u-bg-emerald',
          });
        })
        .catch(() => {
          M.toast({
            html: 'Night incomplete server error',
            classes: 'u-bg-red',
          });
        });
    }
  };

  onMatchDelete = (matchId) => {
    const updatedMatches = this.state.matches.filter(
      (match) => match.id !== matchId,
    );
    this.setState({ matches: updatedMatches });
  };

  onCompleteNight = () => {
    const { matches, date, isSynced } = this.state;
    const { venue_id } = this.props;

    const errors = validateMatchesCompleteness(matches, isSynced);

    if (errors.length > 0) {
      const errorMsg = errors.join(',<br> ');
      M.toast({
        html: `It is not possible to complete night. Please review the issues below: <br>${errorMsg}`,
        classes: 'u-bg-red',
      });

      return;
    }

    this.setState({ isLoading: true });

    return axios
      .post('/api/reports.json', {
        report: { date, venue_id, completed_night: true },
      })
      .then((res) => {
        this.setState({ isCompletedNight: true, isLoading: false });
        M.toast({
          html: 'Night has been completed',
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        M.toast({
          html: `It is not possible to complete night. Please review the issues below: <br>${getErrorTextFromObject(
            error,
          )}`,
          classes: 'u-bg-red',
        });
      });
  };

  render() {
    const {
      date,
      additionalIncome,
      isSynced,
      isCompletedNight,
      isLoading,
      isCompletedNightVisible,
      isLeagueNotesVisible,
      currencySymbol,
      isAttendantsDisabled,
    } = this.state;

    const {
      is_admin: isAdmin,
      venue_id,
      bye_teams: byeTeams,
      admin_note: adminNote,
      manager_note: managerNote,
    } = this.props;

    const matches = this.state.matches.filter(
      (match) => match.start_date === date,
    );

    if (matches.length === 0) return <EmptySchedule />;

    const total = matches.reduce((previousValue, currentItem) => {
      const paidA = parseFloat(currentItem.paid_a) || 0;
      const paidB = parseFloat(currentItem.paid_b) || 0;

      return previousValue + paidA + paidB;
    }, parseFloat(additionalIncome));

    return (
      <React.Fragment>
        <div className="card">
          <Row classMod="u-mb-2">
            <Col l="12">
              <div className="table__wrapper">
                <table className="schedule__table">
                  <thead>
                    <tr>
                      <th className="schedule__cell schedule__cell--time">
                        <span className="table__head_wrapper">
                          Tee time
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--course">
                        <span className="table__head_wrapper">
                          Course
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--league">
                        <span className="table__head_wrapper">
                          League
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--round">
                        <span className="table__head_wrapper u-text-center">
                          Round
                        </span>
                      </th>
                      {/* Team title */}
                      <th className="schedule__cell schedule__cell--team" />
                      {!isAttendantsDisabled && (
                        <th className="schedule__cell schedule__cell--attendants">
                          <span className="table__head_wrapper">
                            Att
                          </span>
                        </th>
                      )}
                      <th className="schedule__cell schedule__cell--score">
                        <span className="table__head_wrapper u-text-center">
                          Score
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--other">
                        <span className="table__head_wrapper u-text-center">
                          Other
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--fee">
                        <span className="table__head_wrapper u-text-center">
                          Fee
                        </span>
                      </th>
                      <th className="schedule__cell schedule__cell--paid">
                        <span className="table__head_wrapper u-text-center">
                          Pay
                        </span>
                      </th>
                      {/* Add Operation */}
                      <th className="schedule__cell schedule__cell--actions" />
                    </tr>
                  </thead>
                  <tbody>
                    {matches.map((match) => (
                      <MatchItem
                        match={match}
                        isExtendedControlsVisible={isAdmin}
                        isAdmin={isAdmin}
                        venueId={venue_id}
                        date={date}
                        onMatchUpdate={this.onMatchUpdate}
                        onMatchDelete={this.onMatchDelete}
                        key={match.id}
                        currencySymbol={currencySymbol}
                        isAttendantsDisabled={isAttendantsDisabled}
                        onUpdateMatchPayment={this.updateMatches}
                      />
                    ))}
                  </tbody>
                </table>

                <Row>
                  <Col l="5">
                    <ByeTeams
                      teamsList={byeTeams}
                      isExtendedControlsVisible={isAdmin}
                    />
                  </Col>

                  <Col offset="1" l="6">
                    <AdditionIncome
                      amount={additionalIncome}
                      venue_id={venue_id}
                      date={date}
                      onAdditionalIncomeUpdate={
                        this.onAdditionalIncomeUpdate(venue_id, date)
                      }
                    />

                    <Total value={total} />

                    <div className="u-relative">
                      <Spinner
                        isLoading={isLoading}
                        position={{ top: -40, right: 30 }}
                      />
                    </div>

                    <div className="u-pr-3 d-flex justify-content-flex-end">
                      {isCompletedNightVisible && (
                        <CompleteNightButton
                          isCompletedNight={isCompletedNight}
                          onCompleteNight={this.onCompleteNight}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <div className="u-pb-2" />

        {isLeagueNotesVisible && (
          <div className="card">
            <div className="table__wrapper">
              <div className="u-weight-500 u-pt-1 u-pb-3 u-font-size-12">
                League Notes
              </div>

              {isAdmin ? (
                <NotesRichEditor
                  message={adminNote.message}
                  venue_id={venue_id}
                  date={date}
                />
              ) : (
                <Row classMod="u-mb-2">
                  <Col l="12">
                    <div className="schedule__notes">
                      <div className="ql-snow">
                        <div
                          className="day_notes_message ql-editor"
                          dangerouslySetInnerHTML={{
                            __html: adminNote.message,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        )}

        {!isAdmin && (
          <React.Fragment>
            <div className="u-pb-2" />

            <div className="card">
              <div className="table__wrapper">
                <Row classMod="u-mb-2">
                  <Col l="12">
                    <div className="u-weight-500 u-pt-1 u-pb-3 u-font-size-12">
                      My Notes
                    </div>
                    <ManagerNotesEditor
                      message={managerNote.message}
                      venue_id={venue_id}
                      date={date}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function Total({ value }) {
  return (
    <div className="u-pr-3 u-pb-5 d-flex justify-content-flex-end align-items-center">
      <label
        className="u-pr-2 u-font-size-12"
        htmlFor="schedule_total"
      >
        Total
      </label>

      <input
        className="browser-default schedule__input-field schedule__input-field--totals"
        type="number"
        id="schedule_total"
        name="schedule[total]"
        value={value}
        disabled
      />
    </div>
  );
}

Total.propTypes = {
  value: PropTypes.number.isRequired,
};

function CompleteNightButton({ isCompletedNight, onCompleteNight }) {
  return (
    <React.Fragment>
      {isCompletedNight && (
        <button
          className="Button Button--primary Button--medium"
          disabled
        >
          Completed
        </button>
      )}
      {!isCompletedNight && (
        <button
          onClick={onCompleteNight}
          className="Button Button--primary Button--medium"
        >
          Complete Round
        </button>
      )}
    </React.Fragment>
  );
}
CompleteNightButton.propTypes = {
  isCompletedNight: PropTypes.bool.isRequired,
  onCompleteNight: PropTypes.func.isRequired,
};

function ByeTeams({ teamsList, isExtendedControlsVisible }) {
  if (teamsList.length === 0) return null;
  const items = teamsList.map((team) => isExtendedControlsVisible ? (
      <a href={`/teams/${team.id}`} key={team.id}>
        {team.title}
      </a>
    ) : (
      <span key={team.id}>{team.title}</span>
    ),
  );

  return (
    <Row classMod="u-mb-2">
      <Col l="12">
        <div className="u-weight-500 u-pb-2 u-font-size-12">
          Bye teams:
        </div>
        <div className="u-font-size-12">
          {componentsJoin(items, ', ')}
        </div>
      </Col>
    </Row>
  );
}
ByeTeams.propTypes = {
  teamsList: PropTypes.array.isRequired,
  isExtendedControlsVisible: PropTypes.bool.isRequired,
};

function EmptySchedule() {
  return (
    <Row classMod="u-mb-2">
      <Col l="12">
        <div className="table__empty">
          <div className="inline-group">
            <div className="u-color-silver-chalice empty-schedule">
              There are no scheduled matches yet.
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
