import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const INPUT_ID = {
  SUBDOMAIN: 'subdomain',
  BRAND_COLOR: 'brand_color',
};

export default class InputWithConfirmation extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: 'text',
    placeholder: ' ',
  };

  state = {
    value: this.props.value,
    isEditMode: false,
  };

  toggleEditMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleSubmit = () => {
    this.toggleEditMode();
    this.props.onSubmit(this.state.value);
  };

  handleCancel = () => {
    this.toggleEditMode();
    this.setState({ value: this.props.value });
  };

  render() {
    const { id, title, placeholder, type } = this.props;
    const { value, isEditMode } = this.state;
    const brandInputClass = classnames({
      'browser-default default-form__input': true,
      'input-brandcolor': id === INPUT_ID.BRAND_COLOR,
    });

    return (
      <div className="default-form__input-group">
        <label htmlFor={id} className="default-form__label">
          {title}
        </label>
        <br />
        <div className="input-with-confirm-container">
          {id === INPUT_ID.BRAND_COLOR && (
            <input
              type="text"
              placeholder={placeholder}
              className="browser-default default-form__input"
              value={value}
              disabled={!isEditMode}
              onChange={this.handleChange}
            />
          )}
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            className={brandInputClass}
            value={value}
            onChange={this.handleChange}
            disabled={!isEditMode}
          />
          <div className="confirm-controls-container">
            {id === INPUT_ID.SUBDOMAIN && (
              <div className="badge badge-primary u-m-1">
                .leagueup.com
              </div>
            )}

            {!isEditMode && (
              <i
                className="far fa-pen u-pointer u-font-size-12 confirm-control u-m-1"
                onClick={this.toggleEditMode}
              />
            )}

            {isEditMode && (
              <React.Fragment>
                <i
                  className="far fa-check-circle u-pointer u-color-emerald confirm-control u-m-1"
                  onClick={this.handleSubmit}
                />
                <i
                  className="far fa-times-circle u-pointer u-color-red confirm-control u-m-1"
                  onClick={this.handleCancel}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
