import PropTypes from 'prop-types';
import React from 'react';
import { SVG } from '../shared/SVG';
import { update, destroy } from '../../lib/API';
import { redirect } from '../../lib/utils';
import { sortMatchesByDateTime } from '../../lib/dateTimeHelper';
import { FixtureActions } from './FixtureActions';
import { MatchRow } from './MatchRow';
import { editFixtureDTO } from '../../lib/DTO';
import {
  getFixtureUrl,
  getLeagueUrl,
  getMatchUrl,
  getRoundUrl,
} from '../../helpers/endpoints';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import {
  affectedMatches,
  validateMatches,
} from '../../lib/validation';
import { TimeslotPreview } from './TimeslotPreview';
import {
  timeslotPreviewData,
  defaultStartTime,
} from '../../lib/timeSlotsHelper';

const START_TIME = defaultStartTime();
const SKIP_VALIDATION = false;

export default class EditFixture extends React.Component {
  static propTypes = {
    fixture: PropTypes.object.isRequired,
    teams: PropTypes.array.isRequired,
    teamsRemovedFromLeague: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    rounds: PropTypes.object.isRequired,
    sport_duration: PropTypes.number.isRequired,
    incompleted_round_numbers: PropTypes.array.isRequired,
    matches_in_the_same_venue: PropTypes.array.isRequired,
  };

  state = {
    rounds: this.props.rounds,
    newId: -10000000, // for new matches
    deleteMatchActive: false,
    deleteMatchData: {},
    deleteRoundActive: false,
    deleteRoundData: {},
    errors: {},
    saveChangesOptionDisabled: true,
    previewActive: false,
    previewData: null,
    affectedMatches: affectedMatches(
      this.props.matches_in_the_same_venue,
    ),
    teamsRemovedFromLeague:
      this.props.teamsRemovedFromLeague.map((item) => {
        item.title += ' (Removed)';
        return item;
      }) || [],
  };

  get teamsOptions() {
    return [{ id: null, title: 'BYE' }, ...this.props.teams];
  }

  validate = () => {
    const { rounds, affectedMatches } = this.state;
    this.normalize();
    this.setState(validateMatches(rounds, affectedMatches));
  };

  normalize = () => {
    const { rounds } = this.state;
    const { courses } = this.props;
    Object.keys(rounds)
      .map((key) => rounds[key])
      .forEach((matches) => matches.forEach((match) => {
          if (match.team_a_id && match.team_b_id) {
            if (!match.start_time) {
              this.updateMatchField(
                match.round,
                match.id,
                'start_time',
                START_TIME,
                SKIP_VALIDATION,
              );
            }
            if (!match.course_id) {
              this.updateMatchField(
                match.round,
                match.id,
                'course_id',
                courses[0].id,
                SKIP_VALIDATION,
              );
            }
          } else if (match.start_time || match.course_id) {
            this.updateMatchField(
              match.round,
              match.id,
              'start_time',
              null,
              SKIP_VALIDATION,
            );
            this.updateMatchField(
              match.round,
              match.id,
              'course_id',
              null,
              SKIP_VALIDATION,
            );
          }
        }),
      );
  };

  handleDeleteRound = (round) => {
    const {
      fixture: { league_id },
    } = this.props;
    this.setState({
      deleteRoundActive: true,
      deleteRoundData: { league_id, round },
    });
  };

  handleConfirmDeleteRound = () => {
    const {
      deleteRoundData: { league_id, round },
    } = this.state;
    destroy({}, getRoundUrl(league_id, round), () => {
      location.reload()
      this.setState({ deleteRoundActive: false });
    },);
  };

  handleCancelDeleteRound = () => {
    this.setState({ deleteRoundActive: false });
  };

  askDeleteMatch = (round, id) => {
    const {
      fixture: { league_id },
    } = this.props;
    this.setState({
      deleteMatchActive: true,
      deleteMatchData: { league_id, round, id },
    });
  };

  handleConfirmDeleteMatch = () => {
    const {
      deleteMatchData: { league_id, round, id },
    } = this.state;
    let { rounds } = this.state;

    // When we delete a game that has not yet been saved
    if (id < 0) {
      this.setState((s) => ({
        deleteMatchActive: false,
        rounds: {
          ...s.rounds,
          [round]: [...s.rounds[round].filter((m) => m.id !== id)],
        },
      }));
      return true;
    }

    destroy({}, getMatchUrl(league_id, id), ({ match }) => {
      let matches = rounds[round];
      matches = matches.filter((m) => m.id != match.id);
      rounds[round] = matches;

      if (!matches.length) {
        rounds = Object.keys(rounds)
          .filter((r) => !!rounds[r].length)
          .reduce((obj, key) => {
            const round_number = key == 1 ? key : key - 1;
            obj[round_number] = rounds[key];
            return obj;
          }, {});
      }

      this.setState({ rounds, deleteMatchActive: false });
    });
  };

  handleCancelDeleteMatch = () => {
    this.setState({ deleteMatchActive: false });
  };

  swapTeams = (match1, match2, round) => {
    const t1 = match1.team_a_id;
    const t2 = match1.team_b_id;
    this.updateMatchField(
      round,
      match1.id,
      'team_a_id',
      match2.team_a_id,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match1.id,
      'team_b_id',
      match2.team_b_id,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match2.id,
      'team_a_id',
      t1,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match2.id,
      'team_b_id',
      t2,
      SKIP_VALIDATION,
    );
  };

  handleAddMatch = (round) => {
    this.setState(
      (s) => ({
        newId: s.newId - 1,
        rounds: {
          ...s.rounds,
          [round]: [
            ...s.rounds[round],
            {
              ...s.rounds[round]
                .sort(sortMatchesByDateTime)
                .slice(-1)[0],
              id: s.newId,
              team_a_id: null,
              team_b_id: null,
              score_a: null,
              score_b: null,
              duration: this.props.sport_duration,
              updated: true,
            },
          ],
        },
      }),
      this.validate,
    );
  };

  handleSave = (publish) => {
    const {
      fixture: { league_id },
      rounds: originalRounds,
    } = this.props;
    const { rounds } = this.state;
    update(
      editFixtureDTO({ rounds, originalRounds, publish }),
      getFixtureUrl(league_id),
      this.afterSave,
      this.onSaveError,
    );
  };

  handlePreviewSwitch = () => {
    this.setState((s) => ({
      previewActive: !s.previewActive,
      previewData: s.previewActive
        ? null
        : timeslotPreviewData(this.state.rounds, this.props.teams),
    }));
  };

  afterSave = () => {
    const {
      fixture: { league_id },
    } = this.props;
    redirect(getLeagueUrl(league_id));
  };

  onSaveError = (invalidMatches) => {
    const { rounds } = this.state;
    const invalidIds = invalidMatches.map(
      ({ match_id }) => +match_id,
    );
    const errors = {};

    Object.keys(rounds).forEach((r) => {
      rounds[r].forEach(({ id }) => {
        if (invalidIds.includes(+id)) {
          if (!errors[r]) {
            errors[r] = {};
          }
          errors[r][id] = 'red';
        }
      });
    });
    this.setState({ errors });
  };

  updateMatchField = (
    round,
    match_id,
    field,
    value,
    skipValidation = false,
  ) => {
    const matches = this.state.rounds[round];
    const match = matches.find((m) => m.id === match_id);
    const m_index = matches.indexOf(match);
    match[field] = value;
    match.updated = true;
    matches[m_index] = match;
    this.setState(
      (s) => ({
        rounds: {
          ...s.rounds,
          [round]: matches,
        },
      }),
      () => {
        !skipValidation && this.validate();
      },
    );
  };

  render() {
    const {
      rounds,
      deleteMatchActive,
      deleteRoundActive,
      saveChangesOptionDisabled,
      previewActive,
      previewData,
    } = this.state;
    const {
      incompleted_round_numbers = [],
      fixture: { league_id },
    } = this.props;

    return (
      <div className="EditFixture">
        <div className="d-flex align-items-baseline justify-content-flex-start">
          <a href={`/leagues/${league_id}`} className="Button u-mr-1">
            Back
          </a>
          <FixtureActions
            position="top"
            disabled={saveChangesOptionDisabled}
            onSave={this.handleSave}
          />
        </div>
        <table className="edit-fixture edit-fixture_left-padded u-mb-8 u-w-100">
          <tbody>
            {Object.keys(rounds).map((r) => this.renderRound(
                rounds[r],
                r,
                incompleted_round_numbers.includes(+r),
              ),
            )}
          </tbody>
        </table>
        <div className="EditFixture-footer">
          <div className="Section">
            <div className="Section-content">
              <div className="u-bg-white u-pt-2 u-pl-2 u-pr-2">
                <FixtureActions
                  position="bottom"
                  disabled={saveChangesOptionDisabled}
                  onSave={this.handleSave}
                  onPreview={this.handlePreviewSwitch}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal
          entity="match"
          active={deleteMatchActive}
          onConfirm={this.handleConfirmDeleteMatch}
          onCancel={this.handleCancelDeleteMatch}
        />
        <ConfirmDeleteModal
          entity="round"
          active={deleteRoundActive}
          onConfirm={this.handleConfirmDeleteRound}
          onCancel={this.handleCancelDeleteRound}
        />
        <TimeslotPreview
          active={previewActive}
          onClose={this.handlePreviewSwitch}
          data={previewData}
        />
      </div>
    );
  }

  renderRound = (matches, round, isIncomplete) => {
    const sortedMatches = matches.sort(sortMatchesByDateTime);
    const { teamsRemovedFromLeague } = this.state;

    return [
      <tr className="edit-fixture__round-row" key={round}>
        <td className="edit-fixture__cell edit-fixture__cell_title">
          round {round}
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_time">
          time
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle">
          course
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_move-actions" />
        <td className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_right-aligned">
          team a
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle">
          team b
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_actions">
          {isIncomplete && (
            <SVG.crossRound
              className="edit-fixture__icon edit-fixture__icon_delete"
              onClick={() => this.handleDeleteRound(round)}
            />
          )}
        </td>
      </tr>,
      sortedMatches.map((match, i) => (
        <MatchRow
          key={match.id}
          value={match}
          round={round}
          prevMatch={sortedMatches[i - 1]}
          nextMatch={sortedMatches[i + 1]}
          courses={this.props.courses}
          errors={this.state.errors}
          onChange={this.updateMatchField}
          onDelete={this.askDeleteMatch}
          onSwap={this.swapTeams}
          teams={this.teamsOptions}
          teamsRemovedFromLeague={teamsRemovedFromLeague}
        />
      )),
      <tr key="add">
        <td colSpan={6} />
        <td className="u-pl-2">
          <SVG.crossRound
            className="edit-fixture__icon edit-fixture__icon_add"
            color="#1194F6"
            onClick={() => this.handleAddMatch(round)}
          />
        </td>
      </tr>,
    ];
  };
}
