import PropTypes from 'prop-types';
import React from 'react';
import Button from '../shared/Button';
import TextInput from '../shared/TextInput';
import Select from '../shared/Select';
import { isValid } from '../../lib/validation';
import { Col, Row } from '../shared/Grid';
import { HOURS_RANGE } from '../../lib/timeSlotsHelper';
import {
  updateSelects,
  updateInputs,
} from '../../lib/materialHelper';
import ModalClosableOutside from '../shared/ModalClosableOutside';

export default class SlotForm extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isVacant: PropTypes.func.isRequired,
    courts: PropTypes.array.isRequired,
    value: PropTypes.object,
    error: PropTypes.string,
  };

  state = this.props.value;

  get isValidSlot() {
    const { court, hours, minutes = 0 } = this.state;
    const { isVacant } = this.props;

    return (
      !!court
      && !!hours
      && isValid(minutes, ['required', 'minutes'])
      && isVacant(this.state)
    );
  }

  get footer() {
    const { onClose, onSubmit } = this.props;

    return (
      <div className="d-flex">
        <Button
          mod="Button--primary u-mr-1"
          title="Submit"
          id="slot_modal_submit_button"
          disabled={!this.isValidSlot}
          onClick={() => onSubmit(this.state)}
        />
        <Button
          mod="Button--cancel"
          title="Cancel"
          id="slot_modal_cancel_button"
          onClick={onClose}
        />
      </div>
    );
  }

  componentDidMount() {
    updateSelects(100);
    updateInputs(100);
  }

  render() {
    const { onDelete, error, courts } = this.props;
    const { court, hours, minutes, isNew } = this.state;

    return (
      <ModalClosableOutside
        onHideModal={this.props.onClose}
        isAllowEmerge
        cssId="slot_modal"
      >
        <div className="d-flex flex-row align-items-center justify-content-between">
          <span className="modal-window__header">
            {isNew ? 'Add ' : 'Edit '}
            slot
          </span>
          {!isNew && (
            <Button
              mod="Button Button--redBg u-font-size-12"
              title="Delete"
              id="modal_slot_delete"
              onClick={() => onDelete()}
            />
          )}
        </div>
        <div className="modal-window__hint">
          Set the time of the game
        </div>
        <Row classMod="u-mb-2">
          <Col l="6">
            <Select
              title="Court"
              required
              items={courts}
              value={court}
              onSelect={(court) => this.setState({ court })}
              id="modal_slot_court"
            />
          </Col>
        </Row>
        <Row classMod="u-mb-2">
          <Col l="6">
            <Select
              mod="select-dropdown_small"
              title="Hours"
              required
              items={HOURS_RANGE}
              value={hours}
              onSelect={(hours) => this.setState({ hours })}
              id="modal_slot_court"
            />
          </Col>
          <Col l="6">
            <TextInput
              mod="text-input__input_small"
              title="Minutes"
              id="modal_slot_minutes"
              validate={['required', 'minutes']}
              type="number"
              value={`${minutes}`}
              onChange={(minutes) => this.setState({ minutes })}
            />
          </Col>
        </Row>
        <br />
        <div className="devise__form__message_error">{error}</div>

        {this.footer}
      </ModalClosableOutside>
    );
  }
}
