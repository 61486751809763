import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'lodash';
import moment from 'moment-timezone';
import Select from '../shared/Select';
import TextInput from '../shared/TextInput';
import Calendar from '../shared/Calendar';
import { HOURS_RANGE } from '../../lib/timeSlotsHelper';
import axios from 'axios';


export default class Listing extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    venues: PropTypes.array.isRequired,
    days: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    ...this.props.listing,
    description: '',
    enableEditDateMode: false,
    tee_start: this.props.listing.tee_start || '',
    tee_end: this.props.listing.tee_end || '',
    start_date: this.props.listing.start_date || moment().format('YYYY-MM-DD'),
    duration: this.props.listing.duration || '',
    capacity: this.props.listing.capacity || '',
    price_per_player: this.props.listing.price_per_player || '',
    currencySymbol: ''
  };

  componentDidMount() {

    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          currencySymbol: response.data.currency_symbol,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  };

  updateField(field, value) {
    const { onChange } = this.props;
    const { listing } = this.state;
    this.setState({ [field]: value }, () => onChange(this.state));
  };

  onChooseDueDate = (date) => {
    const { onChange } = this.props
    this.setState((prevState) => ({
      enableEditDateMode: !prevState.enableEditDateMode,
      start_date: date.format('YYYY-MM-DD')
    }), () => onChange(this.state));
  };

  teeTimeFormatter = (time) => {
    let correctTime = '';
    if (!time) {
        return correctTime
    } else {
        return time
    }
  };

  render() {
    const {
      divisions,
      venues,
      days,
      onChange
    } = this.props;

    const {
      venue_id,
      division_id,
      day,
      tee_start,
      tee_end,
      enableEditDateMode,
      start_date,
      duration,
      capacity,
      price_per_player,
      currencySymbol
    } = this.state;

    const daysObj = days.map((day) => ({
      id: day,
      title: day,
    }));

    const local_date = moment(start_date).format('DD MMM, YYYY');

    return (
      <div className="new-league">
        <div className="u-weight-500 u-pb-2">League details</div>
        <Select
          title="Venue"
          required
          items={venues}
          value={venue_id}
          onSelect={(id) => this.updateField('venue_id', +id)}
          name="listing[venue_id]"
          id="listing_venue_id"
        />
        <Select
          title="Day"
          required
          items={daysObj}
          value={day}
          onSelect={(day) => this.updateField('day', day)}
          name="listing[day]"
          id="listing_day"
        />
        <Select
          title="Division"
          required
          items={divisions}
          value={division_id}
          onSelect={(id) => this.updateField('division_id', +id)}
          name="league[divisions_id]"
          id="league_divisions_id"
        />
        <p className='ListingForm-textarea--title u-pt-1'>
            Tee time range
        </p>
        <div className='d-flex Listing-teetime align-items-center'>
            <Select
                mod="select-dropdown_small"
                title=""
                required
                items={HOURS_RANGE}
                value={this.teeTimeFormatter(tee_start)}
                onSelect={(tee_start) => this.updateField('tee_start', tee_start)}
                id="tee_time_from"
            />
            <span className='Listing-teetime-hyphen'>-</span>
            <Select
                mod="select-dropdown_small"
                title=""
                required
                items={HOURS_RANGE}
                value={this.teeTimeFormatter(tee_end)}
                onSelect={(tee_end) => this.updateField('tee_end', tee_end)}
                id="tee_time_to"
            />
        </div>
                <div
                    className="input-field u-mt-2 Listing-duedate"
                    onClick={() => this.setState({ enableEditDateMode: true })}
                >
                  <span className="calendar-icon">
                    <input
                      id="due_date"
                      type="text"
                      value={local_date}
                      onChange={noop} // in order to eliminate React warning, onChange should be provided here
                    />
                  </span>

                  <label htmlFor="due_date" className="input-field__label select-label_filled active">
                    Start date
                  </label>

                  {enableEditDateMode && (
                    <div className="popup__content">
                      <Calendar
                        onSelect={(value) => this.onChooseDueDate(value)
                        }
                        value={start_date}
                      />
                    </div>
                  )}
                </div>
        <div className='Listing-textinput'>
                <TextInput
                      title="Season Duration"
                      id="season_duration"
                      validate={['required']}
                      value={duration}
                      onChange={(duration) => this.setState({ duration }, () => onChange(this.state))}
                    />
            </div>
            <div className='Listing-textinput'>
                <TextInput
                      title="League Capacity"
                      id="league_capacity"
                      validate={['required']}
                      value={capacity}
                      onChange={(capacity) => this.setState({ capacity }, () => onChange(this.state))}
                    />
            </div>
          <div className='Listing-textinput'>
              <TextInput
                    title="Price per player"
                    id="price_per_player"
                    validate={['required']}
                    measure={currencySymbol}
                    value={price_per_player}
                    onChange={(price) => this.setState({ price_per_player: price }, () => onChange(this.state))}
                  />
          </div>
      </div>
    );
  }
}
