import React, {useEffect, useState, useRef, useCallback} from 'react'
import PropTypes from "prop-types"
import Background from "./Background";
import {tabs} from './util/_tabs'
import { breakdowns, tabNames } from './util/_backoffice_constants';
import TransfersTable from "./payments_table/TransfersTable";
import Spinner from "../shared/Spinner";
import {getJobs} from './util/_backoffice_request_api'
import RefundsTable from './payments_table/RefundsTable';
import UserRefundsTable from './payments_table/UserRefundsTable';
import Balance from './Balance';
import Breakdown from './payments_table/Breakdown';
import DateFilteringBlock from './DateFilteringBlock';
import { buildQueryParams } from '../Payments/util/_build_request_params';


export default function BackOffice({
                                     headLine = 'Payments',
                                 }) {

    const [isLoading, setLoading] = useState(true);
    const [chosenTab, setChosenTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentsList, setPaymentsList] = useState([]);
    const [isLoadingBalance, setLoadingBalance] = useState(true)
    const [availableBalance, setAvailableBalance] = useState(0);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [firstDate, setFirstDate] = useState('');
    const [lastDate, setLastDate] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [downloadingNewPage, setDownloadingNewPage] = useState(true);
    const [totalsBreakdown, setTotalsBreakdown] = useState({});

    const nextPage = useRef(null);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            if (!onNextPage()) {
                setDownloadingNewPage(false);
            }
        }
    };

    useEffect(() => {
        apiRequestBalance()
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    useEffect(() => {
        onChangeFilters();
    }, [firstDate, lastDate]);

    useEffect(() => {
        if (currentPage !== 1)
            apiRequestFunc(true, currentPage, perPage)
    }, [currentPage]);

    useEffect(() => {
        apiRequestFunc(false, 1, perPage)
        onChangeFilters();
    }, [chosenTab]);

    const changeTab = (index) => {
        setLoading(true)
       ; setChosenTab(index)
    }

    const onChangeFilters = () => {
        setLoading(true);
        setCurrentPage(1);
        apiRequestFunc(false, 1, perPage);
    };

    const onNextPage = useCallback(() => {
        if (nextPage.current) {
            setCurrentPage(nextPage.current);
            return true;
        }
        return false;
    }, [nextPage.current]);

    const isBreakdown = tabs[chosenTab].tab === tabNames[breakdowns]

    const apiRequestFunc = (pageChanged, page, perPage) => {
        if (isBreakdown) {
            getJobs(`${tabs[chosenTab].api}?${buildParams(page, perPage)}`)
            .then(({data: {data, next_page, totals}}) => {
                nextPage.current = next_page;
                setTotalsBreakdown(totals);
                setPaymentsList(oldList => pageChanged ? [...oldList, ...data] : data);
                setLoading(false)
            })
        } else {
            const tab = tabs[chosenTab].api === '/transfers' ? 'transfers' : 'refunds';
            getJobs(`${tabs[chosenTab].api}`)
                .then(response => {
                    setPaymentsList(response.data.data[tab])
                    setLoading(false)
                })
        }

    };

    const buildParams = (page, perPage) => {
        const params = {
            filters: {
                date_from: firstDate,
                date_to: lastDate
            },
            page,
            per_page: perPage
        }
        return buildQueryParams(params);
    };

    const apiRequestBalance = () => {
        getJobs(`/balance`)
            .then(({data: {available_balance, pending_balance}}) => {
                setAvailableBalance(available_balance)
                setPendingBalance(pending_balance)
                setLoadingBalance(false)
            })
    };

    const getNecessaryTable = (option) => {
        switch(option) {
            case 'Transfers':
                return <TransfersTable 
                            paymentsList={paymentsList}
                        />
            case 'Refunds':
                return <RefundsTable 
                            paymentsList={paymentsList}
                            updatePaymentsList={apiRequestFunc}
                        />
            case 'User Refunds':
                return <UserRefundsTable 
                            paymentsList={paymentsList}
                        />
            case 'Breakdown':
                return <Breakdown 
                            paymentsList={paymentsList}
                            downloadingNewPage={downloadingNewPage}
                            perPage={perPage}
                            totals={totalsBreakdown}
                        />
        }
    }

    return (
        <div>
            <Background backgroundStyle={'Underlayer-dark'}/>

            <div className="d-flex justify-content-between">
                <h1 className="u-font-size-30 u-m-0 u-pb-3">{headLine}</h1>
            </div>

            <div className="row u-m-0 u-pb-3" style={{position: 'relative'}}>
                {tabs.map(({tab, className}, index) => {
                    const primaryClass = chosenTab === index ? 'Button--primary' : '';
                    return (
                        <div key={index}
                             className={`Button Button--medium ${className} ${primaryClass}`}
                             onClick={() => changeTab(index)}>
                            {tab}
                        </div>
                    )
                })
                }

                <Balance 
                    availableBalance={availableBalance}
                    pendingBalance={pendingBalance}
                    isLoadingBalance={isLoadingBalance}/>
            </div>

            {isBreakdown ? 
                        <div className="d-flex justify-content-flex-start align-items-center u-pb-3">
                        <DateFilteringBlock firstDate={firstDate}
                                            setFirstDate={setFirstDate}
                                            lastDate={lastDate}
                                            setLastDate={setLastDate}>
                        </DateFilteringBlock>               
                    </div>
            : null}


            <div className="card jsLeaguesList">

                <div className="table__wrapper">
                    <Spinner isLoading={isLoading}
                             position={{right: '50%', bottom: '25%'}}
                    />
                    {!isLoading ? getNecessaryTable(tabs[chosenTab].tab): null}
                </div>
            </div>
        </div>
    )
}

BackOffice.propTypes = {
    headLine: PropTypes.string
}
