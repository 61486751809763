import React, {useState} from "react";
import {isInclude} from "./payments_table/TableFilterCell";

export default function DropdownFilteringSelector({
                                                      filter,
                                                      filteredList,
                                                      defaultName,
                                                      chosenOption,
                                                      setFilter,
                                                      onLiClick
                                                  }) {

    const [highlighted, setHighlighted] = useState('')

    return (
        <span
            className="select2-container select2-container--leagues select2-container-open ColumnFilterDropList">
                        <span>
                            <span className={`select2-search select2-search--dropdown`}>
                                <input className="select2-search__field"
                                       type="search"
                                       onChange={(e) => setFilter(e.target.value)}
                                       value={filter}
                                />

                                {filter.length > 0 ? <img src="/assets/icon_clear.png"
                                                          onClick={() => setFilter('')}
                                                          className="icon-cancel-select2"
                                                          alt="clear"
                                /> : null}

                            </span>
                        </span>

                        <span className="select2-results ColumnFilterDropList-rows">
                            <ul role="tree"
                                onMouseLeave={() => setHighlighted('')}
                            >
                                {isInclude(filter, defaultName) ?
                                    <li key={defaultName}
                                        className={`select2-results__option ${highlighted.length === 0 ? 'select2-results__option--highlighted' : ''}`}
                                        role="treeitem"
                                        onClick={() => onLiClick(null)}
                                        aria-selected={chosenOption === null}
                                        onMouseEnter={() => setHighlighted('')}
                                    >
                                        <span>{defaultName}</span>
                                    </li> : null}

                                {filteredList.map(({id, title}) => {
                                        const highlightedClass = id === highlighted ?
                                            'select2-results__option--highlighted' : '';
                                        return (
                                            <li key={id}
                                                className={`select2-results__option ${highlightedClass}`}
                                                role="treeitem"
                                                onClick={() => onLiClick(id)}
                                                aria-selected={id === chosenOption}
                                                onMouseEnter={() => setHighlighted(id)}
                                            >
                                                <span>{title}</span>
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                        </span>
                    </span>
    )
}
