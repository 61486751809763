import PropTypes from 'prop-types';
import React, { useState } from 'react';
import axios from 'axios';
import { Col, Row } from '../shared/Grid';
import Listing from './Listing';
import Button from '../shared/Button';
import { redirect } from '../../lib/utils';
import { buildQueryParams } from '../Registrations/util/_build_request_params';

const REQUIRED_FIELDS = [
    'venue_id',
    'division_id',
    'day',
    'tee_start',
    'tee_end',
    'start_date',
    'capacity',
    'duration',
    'price_per_player'
];

export default function ListingForm({ registration_listing, venues, divisions }) {

    const [currListing, setCurrListing] = useState(registration_listing);

    console.log('registration_listing in props: ', registration_listing)

    const isNew = () => {
        const id = currListing.id;

        return id === null
    };

    const isValid = () => {
        const validDuration = Number.isInteger(Number(currListing.duration)) && Number(currListing.duration) > 0;
        const validCapacity = Number.isInteger(Number(currListing.capacity)) && Number(currListing.capacity) > 0;
        const validPrice = Number(currListing.price_per_player) > 0;

        return validDuration && validCapacity && validPrice
    };
      
    const isLeagueValid = () => {

        return (
        REQUIRED_FIELDS.every((key) => !!currListing[key])  && isValid()
        );
    };
      
    const submit = () => {
        if (isNew()) {
            axios
              .post(`/api/registration_listings?${buildParams()}`)
              .then((res) => {
                  redirect(`/registrations`)
              })
        } else {
            axios
              .patch(`/api/registration_listings/${currListing.id}?${buildParams()}`)
              .then((res) => {
                redirect(`/registrations`)
              })
        }
    };

    const buildParams = () => {
        const params = {
            registration_listing: {
                venue_id: Number(currListing.venue_id),
                division_id: Number(currListing.division_id),
                capacity: Number(currListing.capacity),
                start_date: currListing.start_date,
                tee_start: currListing.tee_start,
                tee_end: currListing.tee_end,
                day: currListing.day,
                duration: Number(currListing.duration),
                description: currListing.description,
                price_per_player: currListing.price_per_player
            }
        }
        return buildQueryParams(params);
    };
     
    const updateDescription = (event) => {
        const value = event.target.value;
        setCurrListing((prev) => ({ ...prev, description: value }))
    };
    
    return (
        <div>
            <Row>
            <Col l="5">
                <Listing
                listing={currListing}
                divisions={divisions}
                venues={venues}
                days={currListing.days}
                onChange={(listing) => {
                    setCurrListing(listing)
                }}
                />
            </Col>
            <Col l="1" />
            <Col l="6">
                <p className='ListingForm-textarea--title'>
                    Listing description:
                </p>
                <textarea 
                    className='ListingForm-textarea--area' 
                    value={currListing.description || ''}
                    onChange={(e) => updateDescription(e)}/>
            </Col>
            <Col l="12" mod="u-pt-2 u-mb-1">
                <div className="d-flex">
                <Button
                    mod="Button--primary u-mr-1"
                    title={`${isNew() ? 'Create' : 'Update'} league`}
                    disabled={!isLeagueValid()}
                    id="create_button"
                    onClick={() => submit()}
                />
                <Button
                    mod="Button--cancel"
                    title="Cancel"
                    id="cancel_button"
                    onClick={() => redirect('/registrations')}
                />
                </div>
            </Col>
            </Row>
        </div>
    );
}

ListingForm.propTypes = {
    registration_listing: PropTypes.shape({
        id: PropTypes.number,
        new_registrations: PropTypes.number,
        day: PropTypes.string,
        days: PropTypes.arrayOf(PropTypes.string).isRequired,
        division: PropTypes.string,
        division_id: PropTypes.number,
        venue: PropTypes.string,
        venue_id: PropTypes.number,
        registered_teams: PropTypes.number,
        start_date: PropTypes.string,
        duration: PropTypes.number,
        capacity: PropTypes.number,
        start_date: PropTypes.string,
        tee_start: PropTypes.string,
        tee_end: PropTypes.string,
        description: PropTypes.string,
        price_per_player: PropTypes.string.isRequired
    }).isRequired,
    venues: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired
        })
    ).isRequired,
    divisions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired
        })
    ).isRequired
}
