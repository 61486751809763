import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { get, createFormData, updateFormData } from '../../lib/API';
import { venueDTO, courseDTO } from '../../lib/DTO';
import TextInput from '../shared/TextInput';
import { Col, Row } from '../shared/Grid';
import Button from '../shared/Button';
import { updateInputs } from '../../lib/materialHelper';
import CourseForm from './CourseForm';
import GoogleSearchAutocomplete from "./GoogleSearchAutocomplete";

export default class VenueForm extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    facility: PropTypes.string,
    address: PropTypes.string,
    deleted_at: PropTypes.instanceOf(Date),
    banner: PropTypes.object,
    icon: PropTypes.object,
    venue_information: PropTypes.string,

    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        deletable: PropTypes.bool,
        status: PropTypes.bool,
      }),
    ).isRequired,
    archived_courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        deletable: PropTypes.bool,
        status: PropTypes.bool,
      }),
    ),
    sports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        deleted_at: PropTypes.instanceOf(Date),
      }),
    ).isRequired,
  };

  state = {
    allProps: this.props,
    id: this.props.id,
    title: this.props.title,
    facility: this.props.facility,
    address: this.props.address,
    deleted_at: this.props.deleted_at,
    banner: this.props.banner,
    icon: this.props.icon,
    venue_information: this.props.venue_information,
    sports: this.props.sports,
    courses: this.props.courses,
    archived_courses: this.props.archived_courses,
    uploaded_banner: {
      name: '',
      file: null
    },
    uploaded_icon: {
      name: '',
      file: null
    },
    allSports: [],
    editableCourse: {
      id: null,
      title: '',
      position: null,
      holes: [
        {
          title: '',
          par: null,
          difficulty: null
        }
      ],
    },
    showForm: false,
    showArchivedCourses: false,
    editMod: 'New',
    coordinates: {lat: null, lng: null}
  };

  componentDidMount() {
    get('/api/settings/sports', (data) => {
      this.setState({ allSports: data });
    });
    updateInputs();
  }

  toggleArchivedStatus = () => {
    this.setState({
      deleted_at: this.isArchived() ? null : new Date(),
    });
  };

  isArchived = () => !!this.state.deleted_at;

  onFormChange = (field, value) => this.setState({ [field]: value });

  disableCourseEditMod = () => {
    this.setState({
      editableCourse: {},
      showForm: false,
    });
  };

  enableCourseEditMod = () => {
    this.setState({
      editableCourse: {
        id: -new Date().getTime(),
        holes: [
          {
            title: '',
            par: null,
            difficulty: null
          }
        ],
      },
      showForm: true,
      editMod: 'New',
    });
  };

  onCourseFormSubmit = (id, position, title, holes) => {
    const { courses, archived_courses } = this.state;

    const not_changed_courses = [...archived_courses, ...courses].filter(
      (item) => item.id !== id,
    );
    const validation = this.validate_course(
      not_changed_courses,
      title,
      holes,
    );
    const coursePosition = !isNaN(position)
      ? position
      : courses.length + 1;

    if (validation) {
      this.setState({
        editableCourse: {
          id: id || -new Date().getTime(),
          title,
          position: coursePosition,
          holes,
          errors: { ...validation },
        },
        showForm: true,
      });
    } else {
      this.setState({
        courses: [
          ...courses.filter((item) => item.id !== id),
          {
            id,
            position: coursePosition,
            title,
            holes,
          },
        ],
        editableCourse: {},
        showForm: false,
      });
    }
  };

  validate_course = (not_changed_courses, title, holes) => {
    const title_exists =      not_changed_courses.filter((item) => item.title === title)
        .length > 0;
    const no_holes = holes.length === 0;
    const empty_pars = holes.find(hole => hole.par === '');
    const pars_not_number = holes.find(hole => Number.isInteger(Number(hole.par)) === false);
    const difficulties_not_number = holes.find(hole => Number.isInteger(Number(hole.difficulty)) === false);
    const empty_difficulties = holes.find(hole => hole.difficulty === '');
    const errors = {};

    if (title_exists) {
      errors.title = 'already exists';
    }
    if (!title) {
      errors.title = 'is required';
    }
    if (no_holes) {
      errors.holes = 'number of holes must be 9, 18 or 27';
    }
    if (empty_pars) {
      errors.holes = 'all pars are required'
    }
    if (pars_not_number) {
      errors.holes = 'par must be a number'
    }
    if (empty_difficulties) {
      errors.holes = 'all indexes are required'
    }
    if (difficulties_not_number) {
      errors.holes = 'index must be a number'
    }

    if (Object.keys(errors).length !== 0) {
      return errors;
    }
  };

  onCourseEdit = (course) => {
    this.setState({
      editableCourse: {
        id: course.id,
        title: course.title,
        position: course.position,
        holes: course.holes,
      },
      showForm: true,
      editMod: 'Edit',
    });
  };

  onDragStart = (e, index) => {
    this.draggedItem = this.state.courses[index];
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (index) => {
    const draggedOverItem = this.state.courses[index];
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    const courses = this.state.courses.filter(
      (item) => item !== this.draggedItem,
    );
    courses.splice(index, 0, this.draggedItem);
    const updated = courses.map((course, index) => {
      course.position = index;
      return course;
    });
    this.setState({ courses: updated });
  };

  onDragEnd = () => {
    this.draggedIdx = null;
  };

  onHandleRestore = (course, index) => {
    const { courses } = this.state;
    courses[index].status = !courses[index].status;
    this.setState({ courses });
  };

  onHandleRemove = (course, index) => {
    const { courses } = this.state;
    if (course.id > 0) {
      courses[index].status = true;
      this.setState({
        archived_courses: [...this.state.archived_courses, course],
      });
    } else {
      courses.splice(index, 1);
    }

    this.setState({
      courses: courses.filter((s) => s.id != course.id),
    });
  };

  onHandleArchiveCourse = (course, index) => {
    const { archived_courses } = this.state;
    archived_courses[index].status = !archived_courses[index].status;

    this.setState({
      archived_courses: archived_courses.filter(
        (s) => s.id != archived_courses[index].id,
      ),
      courses: [...this.state.courses, archived_courses[index]],
    });
  };

  handleAddingFile = (event, field) => {
    const files = event.target.files;
    let file = Array.from(files)[0];

    this.setState({ [field]: {
      name: file.name,
      file: file
    } })
  }

  creationHandler = (venue) => {
    createFormData(
      { venue },
      '/settings/venues',
      (responce) => location.replace('/settings/venues'),
      (errors) => {
        this.setState({ errors: errors.responseJSON });
      },
    );
  };

  updateHandler = (venue) => {
    updateFormData(
      { venue },
      `/settings/venues/${venue.id}`,
      (responce) => location.replace('/settings/venues'),
      (errors) => {
        this.setState({ errors: errors.responseJSON });
      },
    );
  };

  onFormSubmit = () => {
    const {
      id,
      title,
      facility,
      address,
      deleted_at,
      uploaded_banner,
      uploaded_icon,
      courses,
      archived_courses,
      coordinates,
      venue_information
    } = this.state;
    let courses_attributes = courses.map((course, index) => courseDTO(course, index),
    );
    courses_attributes = courses_attributes.concat(
      archived_courses.map((course, index) => courseDTO(course, index)),
    );
 
    const iconFile = uploaded_icon.file || null;
    const bannerFile = uploaded_banner.file || null;
  
    const latitude = coordinates.lat;
    const longitude = coordinates.lng;

    const venue = venueDTO(
      id,
      title,
      facility,
      address,
      latitude,
      longitude,
      iconFile,
      bannerFile,
      deleted_at,
      courses_attributes,
      venue_information
    );
    if (id) {
      this.updateHandler(venue);
    } else {
      this.creationHandler(venue);
    }
  };

  sortByPosition(array) {
    return array.sort((a, b) => {
      const x = a.position;
      const y = b.position;
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  toggleDisplayingArchiveCourses = () => {
    const { showArchivedCourses } = this.state;

    this.setState({ showArchivedCourses: !showArchivedCourses });
  };

  render() {
    const {
      id,
      title,
      facility,
      address,
      banner,
      icon,
      errors,
      editMod,
      showForm,
      editableCourse,
      courses,
      archived_courses,
      showArchivedCourses,
      uploaded_banner,
      uploaded_icon,
      venue_information
    } = this.state;

    return (
      <div className="Card">
        <div className="Card-content u-pt-4 u-pb-5">
          <Row classMod="u-mb-2">
            <Col l="12">
              <div className="d-flex flex-row justify-content-between align-items-baseline">
                <div className="form-title">
                  {id ? 'Edit' : 'Create'} Venue
                </div>
                <Button
                  mod={classNames(
                    'Button Button--small u-font-size-12',
                    { 'Button--red': !this.isArchived() },
                  )}
                  title={this.isArchived() ? 'Restore' : 'Archive'}
                  id="archived-status"
                  onClick={this.toggleArchivedStatus}
                />
              </div>

              <TextInput
                validate={['required']}
                type="text"
                title="Title"
                id="venue_title"
                value={title}
                error={errors && errors.title}
                onChange={(value) => this.onFormChange('title', value)
                }
              />
              <TextInput
                validate={['required']}
                type="text"
                title="Facility"
                id="venue_facility"
                value={facility}
                error={errors && errors.facility}
                onChange={(value) => this.onFormChange('facility', value)
                }
              />
              <div className="input-field input-field_high">
                <GoogleSearchAutocomplete 
                  address={address} 
                  setCoordinates={(latlng) => this.setState({coordinates: latlng})}
                  setAddress={(address) => this.setState({address})}></GoogleSearchAutocomplete>
              </div>
              <div>
                <label htmlFor="venue_information" className="input-field__label VenueInformation-label">
                  Venue Information
                </label>
                <textarea 
                    className='VenueInformation-textarea'
                    id="venue_information"
                    title='Venue Information' 
                    value={venue_information || ''}
                    onChange={(e) => this.onFormChange('venue_information', e.target.value)}/>
              </div>
            </Col>
          </Row>

          <Row classMod="u-mb-2">
            <Col l="12">
              <div className="d-flex flex-row justify-content-between align-items-baseline IconUploader">
                <div className="IconUploader_outer-container">
                  <p className="u-font-size-12">Banner image upload</p>
                  <div className="IconUploader_inner-container">

                    {uploaded_banner.name ? 
                      <span className="IconUploader_icon-name">
                      {uploaded_banner.name}
                      <a
                        className="table__action IconUploader_delete-img"
                        onClick={() => this.setState({ uploaded_banner: {name: '', file: null} })}
                      >
                        <i className="far fa-times u-font-size-17 u-ml-1" />
                      </a>
                      </span>
                    : null}
                    
                    <label 
                      className="IconUploader_label u-pointer"
                      htmlFor='bannerInput'>

                        {!uploaded_banner.name ?  
                        <i className="fal fa-cloud-upload IconUploader_icon"></i> 
                        : null}

                    </label>
                  </div>
                  <input
                    data-size="500"
                    accept=".png, .jpg, .jpeg, .svg" 
                    className="d-none"
                    type="file"
                    name="bannerInput"
                    id="bannerInput"
                    onChange={e => this.handleAddingFile(e, 'uploaded_banner')}
                  />
                </div>

                <div className="IconUploader_outer-container">
                  <p className="u-font-size-12">Icon image upload</p>
                  <div className="IconUploader_inner-container">

                    {uploaded_icon.name ? 
                      <span className="IconUploader_icon-name">
                        {uploaded_icon.name}
                        <a
                          className="table__action IconUploader_delete-img"
                          onClick={() => this.setState({ uploaded_icon: {name: '', file: null} })}
                        >
                          <i className="far fa-times u-font-size-17 u-ml-1" />
                        </a>
                      </span> 
                    : null}

                    <label 
                      className="IconUploader_label u-pointer"
                      htmlFor='iconInput'>

                      {!uploaded_icon.name ? 
                        <i className="fal fa-cloud-upload IconUploader_icon"></i>
                      : null}

                    </label>
                  </div>
                  <input
                    data-size="500"
                    accept=".png, .jpg, .jpeg, .pdf"
                    className="d-none"
                    type="file"
                    name="iconInput"
                    id="iconInput"
                    onChange={e => this.handleAddingFile(e, 'uploaded_icon')}
                  />
              </div>
                </div>

            </Col>
          </Row>
          
          {banner.url || icon.url ?
            <Row classMod="u-mb-2">
              <Col l="12">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                  {banner.url ? 
                    <img
                      className="IconUploader_preview"
                      src={banner.thumb.url} alt="banner image" />
                  : null}

                  {icon.url ? 
                    <img
                      className="IconUploader_preview"
                      src={icon.thumb.url} alt="icon image" />
                  : null}
                </div>
              </Col>
            </Row> 
          : null}
          

          <Row classMod="u-mb-2">
            <Col l="12">
              <div className="d-flex flex-row justify-content-between align-items-baseline u-mt-3">
                <span className="form-title">Courses</span>
                <Button
                  mod="Button--small u-font-size-12"
                  title="Add course"
                  id="add-course"
                  disabled={showForm}
                  onClick={this.enableCourseEditMod}
                />
              </div>
              <div>
                {courses && (
                  <ul>
                    {this.sortByPosition(courses).map(
                      (course, index) => (
                        <li
                          key={course.id}
                          onDragOver={() => this.onDragOver(index)}
                        >
                          <div
                            className="drag d-flex flex-row justify-content-between align-items-baseline u-mt-1"
                            draggable
                            onDragStart={(e) => this.onDragStart(e, index)
                            }
                            onDragEnd={this.onDragEnd}
                          >
                            <div className="u-w-30">
                              {course.status ? (
                                <s>{course.title}</s>
                              ) : (
                                course.title
                              )}
                            </div>
                            <div className="u-w-60">
                              {`${course.holes.length} holes`}
                            </div>
                            <div className="u-w-10 d-flex">
                              {!course.status && (
                                <a
                                  className="table__action"
                                  onClick={() => this.onCourseEdit(course)
                                  }
                                >
                                  <i className="far fa-pen" />
                                </a>
                              )}
                              {course.status && (
                                <a
                                  className="table__action u-ml-1"
                                  onClick={() => this.onHandleRestore(course, index)
                                  }
                                >
                                  activate
                                </a>
                              )}
                              {!course.status && (
                                <a
                                  className="table__action"
                                  onClick={() => this.onHandleRemove(course, index)
                                  }
                                >
                                  <i className="far fa-times u-font-size-17 u-ml-1" />
                                </a>
                              )}
                            </div>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                )}
              </div>

              {archived_courses.length > 0 && (
                <div>
                  <div className="d-flex flex-row justify-content-between align-items-baseline u-mt-5">
                    <span className="form-title">
                      {archived_courses.length} Courses in Archive
                    </span>
                    <button
                      className="Button Button--small u-font-size-12"
                      onClick={this.toggleDisplayingArchiveCourses}
                    >
                      {showArchivedCourses
                        ? 'Hide archive'
                        : 'Show archive'}
                    </button>
                  </div>

                  {showArchivedCourses && (
                    <ul>
                      {this.sortByPosition(archived_courses).map(
                        (course, index) => (
                          <li key={index}>
                            <div className="drag d-flex flex-row justify-content-between align-items-baseline u-mt-1">
                              <div className="u-w-30">
                                {course.title}
                              </div>
                              <div className="u-w-60">
                                {`${course.holes.length} holes`}
                              </div>
                              <div className="u-w-10 u-text-right">
                                <a
                                  className="table__action"
                                  onClick={() => this.onHandleArchiveCourse(
                                      course,
                                      index,
                                    )
                                  }
                                >
                                  <i className="far fa-trash-restore" />
                                </a>
                              </div>
                            </div>
                          </li>
                        ),
                      )}
                    </ul>
                  )}
                </div>
              )}

              {showForm && (
                <CourseForm
                  course={editableCourse}
                  editMod={editMod}
                  onSubmit={(id, position, title, holes) => this.onCourseFormSubmit(
                      id,
                      position,
                      title,
                      holes,
                    )
                  }
                  onCancel={this.disableCourseEditMod}
                />
              )}
            </Col>
          </Row>

          <div className="d-flex">
            <Button
              mod="Button--primary u-mr-1"
              title="Save changes"
              id="create-venue-button"
              onClick={this.onFormSubmit}
            />
            <Button
              mod="Button--cancel"
              title="Cancel"
              id="cancel-venue-form-button"
              onClick={() => location.replace('/settings/venues')}
            />
          </div>
        </div>
      </div>
    );
  }
}
