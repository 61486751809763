import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { create } from '../../lib/API';
import { dateRange } from '../../lib/dateTimeHelper';
import { finalFixtureDTO, fixtureMap } from '../../lib/DTO';
import { redirect } from '../../lib/utils';
import SlotsCount from '../FixturesForm/SlotsCount';
import Step from '../FixturesForm/Step';
import TimeSlots from '../FixturesForm/TimeSlots';
import Button from '../shared/Button';
import Calendar from '../shared/Calendar';
import Select from '../shared/Select';

const DATE_FORMAT = 'D MMMM YYYY';

export default class FinalFixturesForm extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    fixture: PropTypes.object,
    formats: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    sport_duration: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      fixture: { start_date, non_playing_dates, format, team_ids },
      formats,
      league,
    } = this.props;
    const choosedSlot = formats.filter((obj) => format === obj.id)[0];

    this.state = {
      league_id: league.id,
      start_date: start_date ? moment(start_date) : null,
      slots_number: choosedSlot ? choosedSlot.slots_number : 1,
      non_playing_dates: non_playing_dates
        ? non_playing_dates
            .split(',')
            .map((d) => moment(d, 'DD/MM/YYYY'))
        : [],
      team_ids,
      format,
      selected: [],
      currentStep: !!start_date && !!format ? 2 : 1,
    };
  }

  setLoadedTimeslots = (timeslots) => {
    this.setState({ selected: timeslots });
  };

  get dateSet() {
    const { non_playing_dates } = this.state;

    return dateRange(non_playing_dates).map((d, i) => (
      <div className="step__value_item" key={i}>
        {d}
      </div>
    ));
  }

  componentDidMount() {
    const { currentStep } = this.state;
    const { fixture } = this.props;

    if (currentStep === 2) {
      this.afterValidateStep1(fixture);
    }
  }

  goBack() {
    const { league } = this.props;
    redirect(`/leagues/${league.id}`);
  }

  validateStep1() {
    const { league } = this.props;
    const { start_date, format, non_playing_dates } = this.state;

    if (!!start_date && !!format) {
      const data = {
        final_fixture_wizard: {
          start_date: start_date.format('YYYY-MM-DD'),
          format,
          non_playing_dates: non_playing_dates
            .map((d) => d.format('DD/MM/YYYY'))
            .join(','),
        },
        league_id: league.id,
        current_step: 'step1',
      };
      create(
        data,
        `/leagues/${league.id}/final_fixture_wizard/validate_step`,
        (responce) => this.afterValidateStep1(responce.wizard.final_fixture),
      );
    }
  }

  afterValidateStep1(fixture) {
    this.setState({ ...fixtureMap(fixture), currentStep: 2 });
  }

  validateStep2() {
    const { league } = this.props;

    const data = {
      final_fixture_wizard: finalFixtureDTO(this.state),
      current_step: 'step2',
    };
    create(
      data,
      `/leagues/${league.id}/final_fixture_wizard/validate_step`,
      (responce) => this.afterValidateStep2(responce),
    );
  }

  afterValidateStep2() {
    const { league } = this.props;
    redirect(`/leagues/${league.id}/final_fixture_wizard/step3`);
  }

  submit() {
    this.validateStep2();
  }

  render() {
    const { courses, formats, sport_duration } = this.props;
    const {
      currentStep,
      start_date,
      non_playing_dates,
      format,
      slots_number,
      selected,
      id,
    } = this.state;
    const selectedCount = selected.length;

    return (
      <div className="fixture-form">
        <Step
          number="1"
          autoCollapse
          title="Format"
          value={
            (format && formats.find((f) => f.id === format).title)
            || null
          }
        >
          <Select
            title="Format"
            fieldMod="input-field_small"
            id="format"
            items={formats}
            value={format}
            onSelect={(format) => {
              const { slots_number } = formats.filter(
                (obj) => format === obj.id,
              )[0];
              this.setState(
                () => ({
                  slots_number,
                  format,
                }),
                () => this.validateStep1(),
              );
            }}
          />
        </Step>
        <Step
          number="2"
          disabled={!format}
          autoCollapse
          title="Start date"
          value={
            (start_date && [
              moment(start_date).format(DATE_FORMAT),
            ])
            || null
          }
        >
          <Calendar
            value={moment(start_date)}
            onSelect={(start_date) => this.setState({ start_date }, () => this.validateStep1(),
              )
            }
          />
        </Step>
        <Step
          number="3"
          disabled={!start_date}
          title="Non-playing dates"
          value={this.dateSet}
        >
          <Calendar
            multiSelect
            mod="calendar-red-select"
            value={non_playing_dates}
            blockedBefore={start_date}
            onSelect={(non_playing_dates) => this.setState({ non_playing_dates }, () => this.validateStep1(),
              )
            }
          />
        </Step>
        <Step
          number="4"
          disabled={currentStep === 1}
          autoCollapse
          title="Timeslots"
          completed={() => selectedCount === slots_number}
          value={selected.map((s) => s.start_time).join(', ')}
        >
          {id && (
            <div>
              <SlotsCount
                current={selectedCount}
                required={slots_number}
              />
              <TimeSlots
                start_date={start_date}
                loadTimeslots={this.setLoadedTimeslots}
                isAllowAdd={selectedCount < slots_number}
                fixtureId={id}
                courses={courses}
                sport_duration={sport_duration}
                onChangeSelected={(selected) => this.setState({ selected })
                }
              />
            </div>
          )}
        </Step>
        <div className="u-mt-3 d-flex">
          <Button
            mod="Button--primary u-mr-1"
            title="View Fixture Preview"
            disabled={selectedCount !== slots_number}
            id="create_button"
            onClick={() => this.submit()}
          />
          <Button
            mod="Button--cancel"
            title="Cancel"
            id="cancel_button"
            onClick={() => this.goBack()}
          />
        </div>
      </div>
    );
  }
}
