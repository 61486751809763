import React, {useEffect, useState} from 'react'
import {
    buildSportVenuePart,
    parseCompetitionFromDescription,
    parseMatchFeeFromDescription
} from "../util/_payment_fields_operations";
import { moneyFormatterZeros } from '../../../lib/utils';
import axios from "axios";

export default function PaymentInformation({payment, option, cancelCallback}) {

    const [paymentHistory, setPaymentHistory] = useState(null);

    useEffect(() => {
        axios.get(`/api/invoices/${payment.id}/histories`)
            .then((response) => {
                    setPaymentHistory(response.data.data);
                },
                () => {
                    cancelCallback();
                    window.alert('Error occurred!')
                })

    }, [])

    if (!paymentHistory)
        return null;

    const {invoice_versions: invoiceVersions, invoice: {description, team, amount}} = paymentHistory;

    return (
        <div className="PaymentModal PaymentModal-width--28 u-element-center u-fixed">
            <h1 className="PaymentTab-title">{option}</h1>

            <h2 className="u-font-size-14 u-weight-500">
                {buildSportVenuePart(parseMatchFeeFromDescription(payment.description),
                    `$${moneyFormatterZeros(amount ? amount : payment.amount)}`)}
            </h2>

            <div className="SchedulePaymentsTab-invoice-info u-p-1 flex-grow-1 u-mt-2">
                <p className="PaymentTab-description u-weight-700 u-font-size-14">{team}</p>
                <p className="PaymentTab-description">{buildSportVenuePart(payment.sport, payment.venue)}</p>
                <p className="PaymentTab-description">{parseCompetitionFromDescription(description)}</p>
            </div>

            {paymentHistory && invoiceVersions.length > 0 &&
            <React.Fragment>
                <p className="u-font-size-12 u-weight-700 u-mt-1">History:</p>
                {invoiceVersions.map(({description, who_done_this, date}, index) => {
                    return (
                        <div key={index}
                             className="PaymentTab-history-container u-p-1 u-mt-1 d-flex justify-content-between">
                            <div>
                                <span className="PaymentTab-author u-font-size-11 u-relative u-weight-700">{date}</span>
                                {description.map(desc => (
                                    <div key={desc}>
                                        <span className="u-font-size-12">{desc}</span>
                                    </div>))}
                            </div>

                            <span className="PaymentTab-author u-font-size-11 u-relative u-weight-700">
                                {who_done_this}
                            </span>
                        </div>
                    )
                })
                }
            </React.Fragment>
            }

            <hr className="u-mt-2 u-mb-1 u-border-black"/>

            <div className="d-flex u-mt-2">
                <button className="Button Button--cancel PaymentTab-button"
                        onClick={() => cancelCallback()}
                >
                    Back
                </button>
            </div>
        </div>
    )
}
