import PropTypes from 'prop-types';
import React from 'react';
import { filter, indexOf, sortBy } from 'lodash';
import moment from 'moment-timezone';
import Slot from './Slot';
import SlotForm from './SlotForm';
import {
  startHour,
  startMinutes,
  MAX_VISIBLE_SLOTS,
  selectedTimeSlots,
} from '../../lib/timeSlotsHelper';
import { timeSlotDTO } from '../../lib/DTO';
import { create, destroy, get, update } from '../../lib/API';
import Button from '../shared/Button';
import { TZ } from '../shared/configs';

const FORMAT = 'HH:mm';

const byTime = (a, b) => moment(a.start).diff(moment(b.start));
const noTZ = (str) => str.replace(/\+\d\d:\d\d$/g, 'Z');

export default class TimeSlots extends React.Component {
  static propTypes = {
    loadTimeslots: PropTypes.func.isRequired,
    isAllowAdd: PropTypes.bool,
    isAllowUpdate: PropTypes.bool,
    isAllowDelete: PropTypes.bool,
    fixtureId: PropTypes.number,
    round_number: PropTypes.number,
    courses: PropTypes.array.isRequired,
    numberModifiableRounds: PropTypes.number,
    wizard: PropTypes.string,
    sport_duration: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    onChangeSelected: PropTypes.func,
  };

  state = {
    start_date: this.props.start_date,
    round_number: this.props.round_number,
    fixtureId: this.props.fixtureId,
    isShowModal: false,
    selectedSlot: null,
    value: null,
    firstColumnIdx: 0,
    courts: this.props.courses.map((s) => `${s.id}`).sort(),
    timeslots: {},
    error: null,
    selectedSlots: [],
  };

  componentDidMount() {
    this.loadSlots();
  }

  UNSAFE_componentWillReceiveProps({ start_date, round_number }) {
    if (
      this.state.round_number !== round_number
      || this.state.start_date !== start_date
    ) {
      this.loadSlots();
    }
    this.setState({ start_date, round_number });
  }

  loadSlots = () => {
    const {
      courses,
      onChangeSelected,
      numberModifiableRounds,
      wizard,
    } = this.props;
    const { fixtureId } = this.state;

    fixtureId
      && this.setState({ timeslots: {}, loadedCourses: 0 }, () => courses.forEach(({ id }) => {
          const url = `/fixtures/${fixtureId}/timeslots?course_id=${id}`;

          return get(
            numberModifiableRounds
              ? `${url}&modifiable_rounds=${numberModifiableRounds}&wizard=${wizard}`
              : url,
            (data) => {
              this.setState(
                (s) => {
                  const timeslots = { ...s.timeslots, [id]: data };
                  const selectedSlots = selectedTimeSlots(timeslots);
                  const loadedCourses = s.loadedCourses + 1;
                  if (
                    onChangeSelected
                    && loadedCourses === courses.length
                  ) {
                    onChangeSelected(selectedSlots);
                  }
                  return { timeslots, selectedSlots, loadedCourses };
                },
                () => this.props.loadTimeslots(this.state.selectedSlots),
              );
            },
          );
        }),
      );
  };

  sortedCourses(ids) {
    const { courses } = this.props;
    const result = filter(courses, () => indexOf(ids));
    return sortBy(result, 'position');
  }

  isVacant = ({ court, hours, minutes }) => {
    const duration = this.props.sport_duration;
    const slots = this.state.timeslots[court] || [];
    const time = moment({
      hour: moment(hours, 'h A').hours(),
      minute: minutes,
    });
    const newStart = time.format(FORMAT);
    const newEnd = time.add(duration, 'minutes').format(FORMAT);

    const intersect = slots.find(({ start, end }) => {
      const itemStart = moment(start).format(FORMAT);
      const itemEnd = moment(end).format(FORMAT);
      return (
        (itemStart <= newStart && newStart < itemEnd)
        || (itemStart < newEnd && newEnd <= itemEnd)
      );
    });
    return !intersect;
  };

  afterEditSlot = (data) => {
    if (data.isNew) {
      this.addSlot(data);
    } else {
      this.updateSlot(data);
    }
  };

  updateSlot = (data) => {
    const { selectedSlot, fixtureId } = this.state;

    const { numberModifiableRounds, wizard } = this.props;

    let url;
    if (numberModifiableRounds) {
      url = `/fixtures/${fixtureId}/timeslots/${selectedSlot.id}?modifiable_rounds=${numberModifiableRounds}&wizard=${wizard}`;
    } else {
      url = `/fixtures/${fixtureId}/timeslots/${selectedSlot.id}`;
    }

    update(
      { timeslot: timeSlotDTO(data) },
      url,
      () => this.setState(
          { isShowModal: false, error: null },
          this.loadSlots,
        ),
      (error) => {
        this.setState({ error: error.responseJSON.base[0] });
      },
    );
  };

  deleteCurrentSlot = () => {
    const { selectedSlot, fixtureId } = this.state;

    this.setState({ isShowModal: false, error: null });

    destroy(
      {},
      `/fixtures/${fixtureId}/timeslots/${selectedSlot.id}`,
      () => this.setState(
          { isShowModal: false, error: null },
          this.loadSlots,
        ),
    );
  };

  addSlot = (data) => {
    const { fixtureId } = this.state;
    const { onAdd, numberModifiableRounds, wizard } = this.props;

    let url;
    if (numberModifiableRounds) {
      url = `/fixtures/${fixtureId}/timeslots?modifiable_rounds=${numberModifiableRounds}&wizard=${wizard}`;
    } else {
      url = `/fixtures/${fixtureId}/timeslots`;
    }

    create(
      { timeslot: timeSlotDTO(data) },
      url,
      ({ timeslot }) => {
        this.setState(
          { isShowModal: false, error: null },
          this.loadSlots,
        );
        onAdd && onAdd(timeslot);
      },
      (error) => {
        this.setState({ error: error.responseJSON.base[0] });
      },
    );
  };

  select(court, item) {
    const { isAllowAdd = true } = this.props;
    const slot = this.state.timeslots[court].find(
      (slot) => slot.id === item.id,
    );
    const hours = 19;

    if ((isAllowAdd && !slot) || !!slot) {
      this.setState({
        selectedSlot: { ...slot, court, hours },
        value: slot
          ? {
              court,
              hours: startHour(slot),
              minutes: startMinutes(slot),
              isNew: false,
            }
          : {
              court,
              hours,
              minutes: 0,
              isNew: true,
            },
        isShowModal: true,
      });
    }
  }

  createNew(court) {
    this.setState({
      value: {
        court,
        hours: '12 PM',
        minutes: 0,
        isNew: true,
      },
      isShowModal: true,
    });
  }

  handleChangeVisibleColumn(step) {
    const { courts, firstColumnIdx } = this.state;
    const newIndex = firstColumnIdx + step;

    if (
      newIndex < 0
      || newIndex > courts.length - MAX_VISIBLE_SLOTS
    ) {
      return;
    }
    return this.setState({ firstColumnIdx: newIndex });
  }

  get visibleCourts() {
    const { courts, firstColumnIdx } = this.state;
    const courtsList = courts.slice(
      firstColumnIdx,
      firstColumnIdx + MAX_VISIBLE_SLOTS,
    );
    return this.sortedCourses(courtsList);
  }

  render() {
    const {
      courts,
      isShowModal,
      value,
      error,
      timeslots,
    } = this.state;
    const { isAllowAdd } = this.props;
    const showNavigateArrowsBlock = courts.length > MAX_VISIBLE_SLOTS;
    const { visibleCourts } = this;

    return (
      <div className="timeslots">
        {showNavigateArrowsBlock && (
          <div className="timeslots__arrows-wrapper">
            <div
              onClick={() => this.handleChangeVisibleColumn(-1)}
              className="timeslots__arrow timeslots__arrow-left"
            />
            <div
              className="timeslots__arrow timeslots__arrow-right"
              onClick={() => this.handleChangeVisibleColumn(1)}
            />
          </div>
        )}
        <div className="timeslots__table">
          <div className="timeslots__table__header">
            {visibleCourts.map(({ title, id }) => (
              <div key={id} className="timeslots__header">
                {' '}
                {title}{' '}
              </div>
            ))}
          </div>
          <div className="timeslots__table__content">
            {this.visibleCourts.map((court, index) => (
              <div key={index} className="timeslots__column">
                {(timeslots[court.id] || [])
                  .sort(byTime)
                  .map((slot, i) => (
                    <Slot
                      key={i}
                      isBlocked={false}
                      value={slot}
                      course={court.title}
                      onSelect={(slot) => this.select(court.id, slot)}
                    />
                  ))}
              </div>
            ))}
          </div>

          <Button
            id="add-slot-btn"
            mod="Button--primary u-mr-1"
            disabled={!isAllowAdd}
            onClick={() => this.createNew(this.visibleCourts[0].id)}
            title="add"
          />

          {isShowModal && (
            <SlotForm
              id="edit_slot_modal"
              value={value}
              courts={sortBy(visibleCourts, 'position')}
              isVacant={this.isVacant}
              onSubmit={this.afterEditSlot}
              onDelete={this.deleteCurrentSlot}
              onClose={() => this.setState({ isShowModal: false, error: null })
              }
              error={error}
            />
          )}
        </div>
      </div>
    );
  }
}
