import React, {useEffect, useState} from 'react'
import {
    buildSportVenuePart,
    parseCompetitionFromDescription,
    parseMatchFeeFromDescription
} from "../util/_payment_fields_operations";
import {noop} from "lodash";
import {paid, overdues, upcoming} from '../util/_tabs'
import moment from "moment-timezone";
import Calendar from "../../shared/Calendar";
import {statuses} from "../util/_statuses";
import axios from "axios";
import {
    actions,
    adjustFeeAmount,
    adjustInvoiceDate,
    adjustPaymentAmount,
    adjustPaymentPhrases,
    adjustRefundAmount,
    buttonNames,
    changeFeeActions,
    issueRefund,
    markFeeAsPaid,
    voidFee
} from "../util/_manual_adjust_invoice_constants";
import {putPaymentApi} from "../util/_payments_tabs_api";
import {buildQueryParams} from "../util/_build_request_params";
import {changeFeeOkStatusAction} from "../util/_option_window_callbacks";
import {tabs} from "../Index";
import {onSelect} from "../DateFilteringBlock";
import ModalWindowWireframe from "./ModalWindowWireframe";
import { moneyFormatterZeros } from '../../../lib/utils';

export default function EditPaymentFee({
                                           payment,
                                           option,
                                           currentTab,
                                           setPaymentList,
                                           setPayment,
                                           cancelCallback,
                                           preDefinedAction = null,
                                           availableActions,
                                           tabClass
                                       }) {

    const {id, description, amount, due_date, team, sport, venue, status} = payment

    const [showCalendar, setShowCalendar] = useState(false);
    const [history, setHistory] = useState(null);
    const [dueDate, setDueDate] = useState(due_date);
    const [action, setAction] = useState(preDefinedAction);
    const [reason, setReason] = useState('');
    const [adjustTo, setAdjustTo] = useState(moneyFormatterZeros(amount));
    const [inputStatus, setInputStatus] = useState('');
    const [reasonStatus, setReasonStatus] = useState('');

    useEffect(() => afterChangeAction(), [action])

    useEffect(() => {
        afterChangeAction();
        axios.get(`/api/invoices/${payment.id}/histories`)
            .then(response => {
                setHistory(response.data)
            })
    }, [payment])

    const afterChangeAction = () => {
        setReason('');
        setReasonStatus('');
        setShowCalendar(false);
        setInputStatus('');
        setAdjustTo(parseFloat(amount).toFixed(2));
        setDueDate(due_date);
    }

    const onChangeAction = (e) => {
        const newAction = e.target.value;
        setAction(prevAction => prevAction === newAction ? null : newAction)
    }

    const statusTransition = (action, adjustTo) => {
        switch (action) {
            case adjustFeeAmount:
                return 'open';
            case adjustPaymentAmount:
            case markFeeAsPaid:
                return 'paid';
            case voidFee:
                return 'void';
            default:
                if (parseFloat(history.data.invoice.amount) === parseFloat(adjustTo))
                    return 'full_refund';

                return 'partial_refund'
        }
    }

    const onPutResponseAction = (chosenTab, response) => {
        const updatePayment = response.data.data;
        const tab = tabs[chosenTab].tab;

        if (tab === paid ||
            (statuses[updatePayment.status] === 'Active' &&
                ((tab === overdues && updatePayment.overdue) || (tab === upcoming && !updatePayment.overdue))
            ))
            changeFeeOkStatusAction(payment, response, setPaymentList);

        else
            setPaymentList(prevList => prevList.filter(item => item.id !== updatePayment.id));
    }

    const onConfirm = () => {
        if (reason.length === 0) {
            setReasonStatus('reason is not filled');
            setInputStatus('');
        } else if (/^\d*[,|.]?\d+$/.test(adjustTo)) {
            const params = {
                invoice: {
                    amount: adjustTo,
                    update_reason: reason,
                    due_date: dueDate,
                    status: statusTransition(action, adjustTo)
                }
            }

            putPaymentApi(`/api/invoices/${id}?${buildQueryParams(params)}`)
                .then(response => {
                    switch (response.status) {
                        case 200:
                            onPutResponseAction(currentTab, response);

                            if ([markFeeAsPaid, voidFee].includes(action))
                                cancelCallback();
                            else {
                                setPayment(response.data.data);
                                setAction(null);
                            }
                            break;
                        case 422:
                            const amountIndex = response.data.errors.findIndex(item => item.key === 'amount');

                            if (amountIndex > -1)
                                setInputStatus('Incorrect number')
                            else
                                setInputStatus(response.data.errors[0].message);
                            break;
                        default:
                            window.alert(`Invoice wasn\'t found! Please, refresh current tab`)
                    }
                })
        } else {
            setInputStatus('Incorrect number');
            setReasonStatus('');
        }
    }

    const getListOfActions = (status) => {
        let outActions;
        switch (statuses[status]) {
            case 'Active':
                outActions = [adjustFeeAmount, markFeeAsPaid, voidFee];
                break;
            case 'Paid':
                outActions = [adjustPaymentAmount, issueRefund];
                break;
            case 'Partial refund':
            case 'Fully refund':
                outActions = [adjustRefundAmount];
                break;
            default:
                outActions = [];
        }

        return outActions.filter(item => availableActions.includes(item))
    }

    return (
        <ModalWindowWireframe widthStyle={tabClass}>
            <div>
                <h1 className="u-font-size-20 u-weight-700">{option}</h1>
                <h2 className="u-font-size-14 u-weight-500">
                    {buildSportVenuePart(
                        parseMatchFeeFromDescription(description),
                        `$${moneyFormatterZeros(amount)}`)
                    }
                </h2>
            </div>

            <div className="SchedulePaymentsTab-invoice-info u-p-1 flex-grow-1 u-mt-2">
                <p className="PaymentTab-description u-weight-700 u-font-size-14">{team}</p>
                <p className="PaymentTab-description">{buildSportVenuePart(sport, venue)}</p>
                <p className="PaymentTab-description">{parseCompetitionFromDescription(description)}</p>
            </div>

            {history && history.data.invoice_versions.length > 0 &&
            <React.Fragment>
                <p className="u-font-size-12 u-weight-700 u-mt-1">History:</p>
                {history.data.invoice_versions.map(({description, who_done_this, date}, index) => {
                    return (
                        <div key={index}
                             className="PaymentTab-history-container u-p-1 u-mt-1 d-flex justify-content-between">
                            <div>
                                <span className="PaymentTab-author u-font-size-11 u-relative u-weight-700">{date}</span>
                                {description.map(desc => (
                                    <div key={desc}>
                                        <span className="u-font-size-12">{desc}</span>
                                    </div>))}
                            </div>

                            <span className="PaymentTab-author u-font-size-11 u-relative u-weight-700">
                                {who_done_this}
                            </span>
                        </div>
                    )
                })
                }
            </React.Fragment>
            }

            <hr className="u-mt-2 u-mb-1 u-border-black"/>

            {getListOfActions(status).length > 0 &&
            <p className="PaymentTab-description u-weight-700">
                How would you like to edit the {statuses[status] === 'Active' ? 'fee' : 'payment'}:
            </p>
            }

            <div key={action ? action : 'list-of-actions'}
                 className="u-mt-2">
                {getListOfActions(status).map(item => {
                    const radioName = item === adjustFeeAmount && availableActions.includes(adjustInvoiceDate) ?
                        actions[adjustInvoiceDate] : actions[item];
                    return (
                        <div className="u-mt-1"
                             key={item}>
                            <label className="u-font-size-12">
                                <input id={`edit-payment-fee-${item}-action`}
                                       className="browser-default"
                                       type="radio"
                                       value={item}
                                       checked={action === item}
                                       onClick={onChangeAction}
                                       onChange={noop}
                                />
                                {radioName}
                            </label>
                        </div>)
                })
                }
            </div>

            {action &&
            <React.Fragment>
                <p className="u-font-size-11 u-color-red u-mt-2">{reasonStatus}</p>
                <div className="d-flex u-mt-1">
                    <div className="d-flex u-mr-1">
                        <span className="PaymentTab-label">Reason </span>
                        <span className="u-color-red"> *</span>
                    </div>

                    <input id="edit-payment-fee-reason-input"
                           className="PaymentTab-reason u-relative"
                           onChange={(e) => setReason(e.target.value)}
                           value={reason}
                    />
                </div>

                <p className="u-font-size-11 u-color-red">{inputStatus}</p>

                {changeFeeActions.includes(action) &&
                <div className="u-mt-1 d-flex justify-content-between">
                    <div>
                        <span className="PaymentTab-label u-mr-1">{adjustPaymentPhrases[action]}</span>
                        <input id="edit-payment-fee-amount-input"
                               className="PaymentTab-amount browser-default"
                               type="text"
                               onChange={(e) => setAdjustTo(e.target.value.substring(1))}
                               value={`\$${adjustTo}`}
                        />
                    </div>
                    {adjustFeeAmount === action && availableActions.includes(adjustInvoiceDate) &&
                    <div>
                        <span className="PaymentTab-label u-mr-1">Date due:</span>
                        <input id="edit-due_date-invoice-input"
                               className="u-text-center text-input__input_small"
                               type="text"
                               onClick={() => setShowCalendar(prevState => !prevState)}
                               readOnly={true}
                               value={moment(dueDate).format('DD MMM, YYYY')}
                        />
                        {showCalendar && (
                            <div className="popup__content popup__dropup">
                                <Calendar onSelect={(value) => onSelect(value, setDueDate, setShowCalendar)}
                                          value={dueDate}
                                />
                            </div>
                        )
                        }
                    </div>
                    }
                </div>
                }
            </React.Fragment>

            }

            <div className="d-flex u-mt-3">
                {action &&
                <button
                    className="Button Button--primary PaymentTab-button"
                    onClick={() => onConfirm()}
                >
                    {buttonNames[action]}
                </button>
                }

                <button className="Button Button--cancel PaymentTab-button"
                        onClick={() => cancelCallback()}
                >
                    Cancel
                </button>
            </div>
        </ModalWindowWireframe>
    )
}
