import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { localFormatPhoneNumber } from 'helpers/phoneHelper';
import axios from 'axios';

export default function PlayersList({ teamId }) {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchPlayers = async () => {
      const response = await axios.get(`/api/players`, {
        params: { 'filters[team_id]': teamId },
      });
      return setPlayers(response.data);
    };
    fetchPlayers();
  }, [teamId]);

  function editPlayerURL(playerId) {
    return `/players/${playerId}/edit`;
  }

  function playersFullName({ first_name, last_name }) {
    return first_name.concat(' ', last_name);
  }

  return (
    <Fragment>
      <table className="bordered highlight u-w-100 u-mt-4 u-mb-3">
        <thead className="u-text-left">
          <tr>
            <th>Player name</th>
            <th>Mobile</th>
            <th colSpan="2">Email</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player) => (
            <tr
              className="collection-item"
              data-link={editPlayerURL(player.id)}
              key={player.id}
            >
              <td>{playersFullName(player)}</td>
              <td>{localFormatPhoneNumber(player.phone)}</td>
              <td className="u-color-emerald">{player.email}</td>
              <td>
                <a
                  href={editPlayerURL(player.id)}
                  className="table__action"
                >
                  <i className="far fa-pen" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
}

PlayersList.propTypes = {
  teamId: PropTypes.number.isRequired,
};
