import PropTypes from 'prop-types';
import React from 'react';
import { isValid } from '../../lib/validation';

export default class TextInput extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    mod: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    validate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    measure: PropTypes.string,
    error: PropTypes.array,
    tabIndex: PropTypes.number,
    isDisabled: PropTypes.bool
  };

  static defaultProps = {
    onBlur: () => {},
  };

  constructor(props) {
    super(props);
    this.touched = false;
  }

  get isValid() {
    const { validate, value, error } = this.props;

    if (!validate) {
      return true;
    }
    if (error && !this.touched) {
      return false;
    }
    if (this.touched) {
      return isValid(value, validate);
    }
    return true;
  }

  UNSAFE_componentWillReceiveProps({ error }) {
    if (this.props.error !== error) {
      this.touched = false;
    }
  }

  updateValue({ target: { value } }) {
    const { onChange = () => {} } = this.props;

    this.touched = true;
    onChange(value);
  }

  render() {
    const {
      placeholder = ' ',
      title,
      id,
      measure,
      value,
      error,
      mod = '',
      type = 'text',
      onClick,
      tabIndex,
    } = this.props;

    let otherProps = {};
    otherProps = tabIndex
      ? { ...otherProps, tabIndex }
      : { ...otherProps };

    return (
      <div className="input-field input-field_high">
        <input
          className={`text-input__input ${mod}${
            this.isValid ? '' : ' text-input__input_invalid'
          }`}
          type={type}
          placeholder={placeholder}
          id={id}
          value={value || ''}
          disabled={this.props.isDisabled}
          onChange={(event) => this.updateValue(event)}
          onBlur={this.props.onBlur}
          onClick={onClick}
          {...otherProps}
        />
        <label htmlFor={id} className="input-field__label">
          {title}
        </label>
        {measure && (
          <span className="text-input__measure">{measure}</span>
        )}
        {!!error && !this.touched && (
          <div className="input-field__error">{error.join(', ')}</div>
        )}
      </div>
    );
  }
}
