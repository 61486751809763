import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Step from '../FixturesForm/Step';
import Button from '../shared/Button';
import TimeSlots from '../FixturesForm/TimeSlots';
import { create, get, destroy } from '../../lib/API';
import SlotsCount from '../FixturesForm/SlotsCount';
import { redirect } from '../../lib/utils';
import Select from '../shared/Select';
import Switch from '../shared/Switch';

const SWITCH_VALUES = ['Update', 'Extend'];

export default class FixturesForm extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    fixture: PropTypes.object,
    courses: PropTypes.array.isRequired,
  };

  state = {
    league_id: this.props.league.id,
    from_round: null,
    start_date: moment(this.props.fixture.start_date),
    round_number: this.props.fixture.round_number,
    slots_number: ~~(this.props.teams.length / 2),
    non_playing_dates:
      (this.props.fixture.non_playing_dates
        && this.props.fixture.non_playing_dates
          .split(',')
          .map((d) => moment(d, 'DD/MM/YYYY')))
      || [],
    team_ids: this.props.fixture.team_ids,
    sport_duration: this.props.sport_duration,
    timeslots_data: {},
    loadedCourses: 0,
    newSlots: [],
    currentStep: 1,
    available_rounds: this.props.available_rounds,
    action: 'Update',
    selected: [],
  };

  setLoadedTimeslots = (timeslots) => {
    this.setState({ selected: timeslots });
  };

  goBack = () => {
    const { league, fixture } = this.props;
    destroy({}, `/fixtures/${fixture.id}/mass_timeslots`, () => redirect(`/leagues/${league.id}`),
    );
  };

  handleSelectRound = (from_round) => {
    const { league, wizard } = this.props;
    const data = {
      [wizard]: { from_round },
      league_id: league.id,
      current_step: 'step1',
    };

    create(
      data,
      `/leagues/${league.id}/${wizard}/validate_step`,
      () => {
        this.setState({ from_round: null, action: 'Update' }, () => {
          get(
            `/leagues/${league.id}/${wizard}/step2?${wizard}[from_round]=${from_round}`,
            () => {
              this.setState({ from_round });
            },
          );
        });
      },
    );
  };

  submit = () => {
    const { league, wizard } = this.props;
    const { from_round } = this.state;
    const data = {
      [wizard]: { from_round },
      league_id: league.id,
      current_step: 'step2',
    };

    create(
      data,
      `/leagues/${league.id}/${wizard}/validate_step`,
      () => redirect(
          `/leagues/${league.id}/${wizard}/step3?${wizard}[from_round]=${from_round}`,
        ),
    );
  };

  handleSwitch = (action) => {
    if (action === 'Extend') {
      redirect(
        `/leagues/${this.props.league.id}/extend_week_wizard/step1`,
      );
    }
  };

  render() {
    const { courses, fixture, sport_duration } = this.props;
    const {
      round_number,
      slots_number,
      from_round,
      available_rounds,
      action,
      selected,
    } = this.state;
    const selectedCount = selected.length;
    const numberOfDays = available_rounds.filter(
      (i) => i >= parseInt(from_round),
    ).length;

    return (
      <div className="fixture-form">
        <Step
          number="1"
          autoCollapse
          title="Type"
          value={from_round && action}
          completed={() => from_round}
        >
          <Switch
            values={SWITCH_VALUES}
            value="Update"
            id="switch"
            onSelect={this.handleSwitch}
          />
        </Step>
        <Step
          number="2"
          autoCollapse
          title="From round?"
          value={from_round}
          completed={(v) => v > 0 && v <= round_number}
        >
          <Select
            id="from_round"
            name="from_round"
            title="From round"
            fieldMod="input-field_small"
            required
            validate={['required']}
            items={available_rounds}
            onSelect={this.handleSelectRound}
          />
        </Step>
        <Step
          number="3"
          disabled={!from_round}
          title="Timeslots"
          completed={() => selectedCount === slots_number}
          value={selected.map((s) => s.start_time).join(', ')}
        >
          {from_round && (
            <div>
              <SlotsCount
                current={selectedCount}
                required={slots_number}
              />
              <TimeSlots
                loadTimeslots={this.setLoadedTimeslots}
                isAllowAdd={selectedCount < slots_number}
                fixtureId={fixture.id}
                courses={courses}
                sport_duration={sport_duration}
                numberModifiableRounds={numberOfDays}
                wizard="modify"
                onChangeSelected={(selected) => this.setState({ selected })
                }
              />
            </div>
          )}
        </Step>
        <div className="d-flex u-mt-3">
          <Button
            mod="Button--primary u-mr-1"
            title="See preview"
            disabled={selectedCount !== slots_number}
            id="create_button"
            onClick={this.submit}
          />
          <Button
            mod="Button--cancel"
            title="Cancel"
            id="cancel_button"
            onClick={this.goBack}
          />
        </div>
      </div>
    );
  }
}
