import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Form from '../AddPlayerForm/Form';
import { playerObjectDTO } from '../../lib/DTO';
import {
  addPlayerRoleWithPhone,
  addPlayerRole,
  addPlayer,
} from '../../helpers/addPlayerHelper';

export default function DropdownPlayersList({
  captain,
  items,
  onSelect,
}) {
  const [isPlayerAddMode, setIsPlayerAddMode] = useState(false);
  const [filter, setFilter] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = (
    existingPlayerId,
    title,
    firstName,
    lastName,
    email,
    phone,
  ) => {
    const data = playerObjectDTO(firstName, lastName, email, phone);
    const isNeedToAddPhone =      existingPlayerId && isValidPhoneNumber(phone);

    if (existingPlayerId) {
      isNeedToAddPhone
        ? addPlayerRoleWithPhone(
            existingPlayerId,
            phone,
            onSelect,
            setErrors,
          )
        : addPlayerRole(existingPlayerId, onSelect, setErrors);
    } else {
      addPlayer(data, onSelect, setErrors);
    }
  };

  const options = {
    emailLabel: "Let's start with their email…",
    createButtonLabel: 'Create player',
    cancelButtonPath: '/players',
    userFoundMessage: 'Player already exists on your account',
  };

  return (
    <React.Fragment>
      {isPlayerAddMode && (
        <Form
          formOptions={options}
          errors={errors}
          onSubmit={handleSubmit}
        />
      )}

      {!isPlayerAddMode && (
        <div className="popup__wrapper">
          <div className="popup__search">
            <div className="search-wrapper">
              <i className="far fa-search filter-search-icon" />
              <input
                className="search-field browser-default"
                autoFocus
                onChange={(e) => setFilter(e.target.value)}
                type="text"
                placeholder="Search"
              />
            </div>
          </div>

          <ul>
            <li key={-1} onClick={() => setIsPlayerAddMode(true)}>
              <span className="team-create__link">
                Add new player
              </span>
            </li>

            {items.map(
              (player, index) =>
                player.title
                  .toLowerCase()
                  .includes(filter.toLowerCase()) && (
                  <li
                    key={index}
                    className={classnames({
                      selected: player.id === captain.id,
                    })}
                    onClick={() => onSelect(player)}
                  >
                    <span>{player.title}</span>
                  </li>
                ),
            )}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
}

DropdownPlayersList.propTypes = {
  captain: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};
