import React from 'react'
import PropTypes from "prop-types"
import moment from "moment";
import Background from "../Background";
import {moneyFormatterZeros} from "../../../lib/utils";
import RefundActions from "../RefundActions"

export default function RefundsTable({paymentsList, updatePaymentsList}) {

    const theadClassNames = 'u-pt-2 u-pb-2';

    return (
        <React.Fragment>
            <Background backgroundStyle={'Underlayer-dark'}/>

            <table className="bordered highlight u-w-100">
                <thead>
                <tr className="u-text-left u-noBorder">
                    <th className={theadClassNames}>Created At</th>
                    <th className={theadClassNames}>Amount</th>
                    <th className={theadClassNames}>Invoice Id</th>
                    <th className={theadClassNames}>Company</th>
                    <th className={theadClassNames}>Status</th>
                </tr>
                </thead>
                <tbody>
                {paymentsList.length ? paymentsList.map(payment => {
                        const {
                            id,
                            created_at,
                            amount,
                            invoice_id,
                            status,
                            stop
                        } = payment;
                        return (
                            <tr className="collection-item"
                                key={id}
                            >
                                <td>{moment(created_at).format('LL')}</td>
                                <td>${moneyFormatterZeros(amount)}</td>
                                <td>{invoice_id}</td>
                                <td>{status}</td>
                                <td className='RefundActions-td'>
                                    <RefundActions 
                                        refundId={id} 
                                        stop={stop} 
                                        updatePaymentsList={updatePaymentsList}/>
                                    </td>
                            </tr>
                        )
                    })
                    : <tr><th>There is no invoices...</th></tr> }
                </tbody>
            </table>       
        </React.Fragment>
    )
}

RefundsTable.propTypes = {
    paymentsList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            created_at: PropTypes.string.isRequired,
            amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            invoice_id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            stop: PropTypes.bool.isRequired
        })
    )
}
