import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import classnames from 'classnames';
import { isEmailValid } from '../../lib/utils';
import { getErrorTextFromObject } from '../../helpers/utils';
import {
  PlayerEmail,
  PlayerPhone,
  LastNameVerificator,
  PhoneVerificator,
  PlayerInfo,
} from './Inputs';

import InputControl from './InputControl';

const FORM_SCREEN = {
  INITIAL: 'INITIAL',
  EMAIL_NOT_EXISTS: 'EMAIL_NOT_EXISTS',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  VERIFY_PHONE: 'VERIFY_PHONE',
  VERIFY_LASTNAME: 'VERIFY_LASTNAME',
  isVerificationScreen: (screen) => screen === FORM_SCREEN.VERIFY_PHONE
    || screen === FORM_SCREEN.VERIFY_LASTNAME,
};

Form.propTypes = {
  formOptions: PropTypes.shape({
    emailLabel: PropTypes.string,
    createButtonLabel: PropTypes.string.isRequired,
    cancelButtonPath: PropTypes.string.isRequired,
    userFoundMessage: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function Form({ formOptions, errors, onSubmit }) {
  const {
    emailLabel,
    createButtonLabel,
    cancelButtonPath,
    userFoundMessage,
  } = formOptions;

  const [playerId, setPlayerId] = useState(null);
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameVerificator, setLastNameVerificator] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneVerificator, setPhoneVerificator] = useState('');
  const [isPlayerVerified, setIsPlayerVerified] = useState(false);
  const [screen, setScreen] = useState(FORM_SCREEN.INITIAL);
  const [isLoading, setIsLoading] = useState(false);
  const [existingPlayerInfo, setExistingPlayerInfo] = useState({});

  useEffect(() => {
    setIsPlayerVerified(false);
    _verifyEmail();
  }, [email]);

  useEffect(() => {
    _verifyPhone();
  }, [phoneVerificator]);

  useEffect(() => {
    _verifyLastName();
  }, [lastNameVerificator]);

  useEffect(() => {
    if (
      screen === FORM_SCREEN.INITIAL
      || screen === FORM_SCREEN.EMAIL_NOT_EXISTS
    ) {
      _resetPlayerInfo();
    }
  }, [screen]);

  const _resetPlayerInfo = () => {
    setPlayerId(null);
    setFirstName('');
    setLastName('');
    setPhone('');
  };

  const _verifyEmail = () => {
    if (!isEmailValid(email)) {
      if (screen !== FORM_SCREEN.INITIAL) {
        setScreen(FORM_SCREEN.INITIAL);
      }

      return;
    }

    setIsLoading(true);

    axios
      .get('/api/check_player_exist', {
        params: {
          email,
        },
      })
      .then((res) => {
        const foundPlayer = res.data;
        setIsLoading(false);

        if (foundPlayer.id) {
          setExistingPlayerInfo(foundPlayer);
          setPlayerId(foundPlayer.id);
          setScreen(FORM_SCREEN.EMAIL_EXISTS);
        }
      })
      .catch((error) => {
        const { status: errStatus, data: errData } = error.response;
        setIsLoading(false);

        switch (errStatus) {
          case 409:
            setExistingPlayerInfo(errData);
            setPlayerId(errData.id);

            if (errData.phone) {
              const phoneVisiblePart = errData.phone;
              setPhone(phoneVisiblePart);
              setScreen(FORM_SCREEN.VERIFY_PHONE);
            } else {
              setPhone('');
              setScreen(FORM_SCREEN.VERIFY_LASTNAME);
            }
            break;

          case 404:
            setScreen(FORM_SCREEN.EMAIL_NOT_EXISTS);
            break;

          default:
            const errorText = getErrorTextFromObject(error);
            M.toast({ html: errorText, classes: 'u-bg-red' });
            break;
        }
      });
  };

  const _verifyPhone = () => {
    if (phoneVerificator.length !== 3) {
      setIsPlayerVerified(false);

      return;
    }

    const phoneToVerify = phone + phoneVerificator;
    setIsLoading(true);

    axios
      .get(
        `/api/player_validations/${playerId}?field=phone&value=${phoneToVerify}`,
      )
      .then((res) => {
        const foundPlayer = res.data;
        setIsLoading(false);

        if (foundPlayer.id) {
          setExistingPlayerInfo(foundPlayer);
          setPlayerId(foundPlayer.id);
          setIsPlayerVerified(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsPlayerVerified(false);
      });
  };

  const _verifyLastName = () => {
    if (!lastNameVerificator) return;

    setIsLoading(true);
    axios
      .get(
        `/api/player_validations/${playerId}?field=last_name&value=${lastNameVerificator}`,
      )
      .then((res) => {
        const foundPlayer = res.data;
        setIsLoading(false);

        if (foundPlayer.id) {
          setExistingPlayerInfo(foundPlayer);
          setPlayerId(foundPlayer.id);
          setIsPlayerVerified(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsPlayerVerified(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(playerId, title, firstName, lastName, email, phone);
  };

  const _isCreateButtonEnabled = () => {
    if (screen === FORM_SCREEN.EMAIL_NOT_EXISTS) return true;
    if (screen === FORM_SCREEN.EMAIL_EXISTS) return true;

    return isPlayerVerified;
  };

  const createButtonClass = classnames({
    'Button Button--primary Button--medium u-mr-1': true,
    'Button--disabled': !_isCreateButtonEnabled(),
  });

  const verificationItemText =    screen === FORM_SCREEN.VERIFY_PHONE
      ? 'the final 3 digits of the telephone number'
      : 'their last name';

  return (
    <form
      onSubmit={handleSubmit}
      className="PlayerTeamForm u-pt-4 u-pb-1"
    >
      <PlayerEmail
        email={email}
        onChange={setEmail}
        isLoading={isLoading}
        label={emailLabel}
        errors={errors}
      />

      {screen === FORM_SCREEN.EMAIL_NOT_EXISTS && (
        <React.Fragment>
          <div className="row u-mb-2">
            <div className="col-6">
              <InputControl
                id="first_name"
                label="First name"
                value={firstName}
                onChange={setFirstName}
                error={errors.firstName}
              />
            </div>
            <div className="col-6">
              <InputControl
                id="last_name"
                label="Last name"
                value={lastName}
                onChange={setLastName}
                error={errors.lastName}
              />
            </div>
          </div>

          <PlayerPhone
            value={phone}
            onChange={setPhone}
            errors={errors}
          />
        </React.Fragment>
      )}

      {screen === FORM_SCREEN.EMAIL_EXISTS && (
        <PlayerInfo
          playerInfo={existingPlayerInfo}
          message={userFoundMessage}
          isExistsWarning
        />
      )}

      {FORM_SCREEN.isVerificationScreen(screen) && (
        <React.Fragment>
          <div className="row u-mb-2">
            <div className="col-12">
              <div className="PlayerTeamForm-info">
                <p>
                  The email address {email} is not in your list of
                  players, however it is an existing user account on
                  the NineGolfplatform (at another organisation).
                  Before we can let you add this user see their
                  details, we need to verify that you know them.
                </p>
                <br />
                <p>
                  Please enter
                  {verificationItemText} that they have registered
                  with on NineGolfto proceed.
                </p>
              </div>
            </div>
          </div>

          {screen === FORM_SCREEN.VERIFY_PHONE && (
            <PhoneVerificator
              visiblePartNumber={phone}
              verificationNumber={phoneVerificator}
              onChange={setPhoneVerificator}
            />
          )}

          {screen === FORM_SCREEN.VERIFY_LASTNAME && (
            <LastNameVerificator
              lastName={lastNameVerificator}
              onChange={setLastNameVerificator}
            />
          )}

          {isPlayerVerified && (
            <PlayerInfo playerInfo={existingPlayerInfo} />
          )}

          {isPlayerVerified
            && screen === FORM_SCREEN.VERIFY_LASTNAME && (
              <PlayerPhone
                value={phone}
                onChange={setPhone}
                errors={errors}
              />
            )}
        </React.Fragment>
      )}

      <div className="d-flex u-pb-5 u-pt-3">
        <button
          type="submit"
          className={createButtonClass}
          disabled={!_isCreateButtonEnabled()}
        >
          {createButtonLabel}
        </button>
        <a
          href={cancelButtonPath}
          className="Button Button--medium u-lh-20"
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
