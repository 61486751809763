const parseMatchFeeFromDescription = (description) => {
    const index = description.indexOf('|');
    return index === -1 ? description : description.substring(0, index).trim();
}

const parseSportVenueCompetition = (description) => {
    const index = description.indexOf('|');
    return index === -1 ? '' : description.substring(index + 1).trim();
}

const parseCompetitionFromDescription = (description) => {
    const index = description.lastIndexOf('|');
    return index === -1 ? '' : description.substring(index + 1).trim();
}

const buildSportVenuePart = (sport, venue) => {
    const isSport = sport && sport.length > 0;
    const isVenue = venue && venue.length > 0;

    if (isSport && isVenue)
        return `${sport} | ${venue}`
    else if (!isVenue && !isSport)
        return ''
    else
        return isSport ? sport : venue
}

export {
    parseMatchFeeFromDescription,
    parseSportVenueCompetition,
    parseCompetitionFromDescription,
    buildSportVenuePart
}
