import axios from 'axios';

const getListingWithFilters = async (path) => {
    return axios({
        method: 'get',
        url: `/api${path}`
    });
};

export {
    getListingWithFilters
}
