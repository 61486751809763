import React from "react";
import {buildSportVenuePart, parseMatchFeeFromDescription, parseSportVenueCompetition} from "../util/_payment_fields_operations";
import { moneyFormatterZeros } from "../../../lib/utils";

export default function OverallWindowPart({payment, option, invoiceAmount = null}) {

    const {description, team, amount} = payment;

    return (
        <React.Fragment>
            <h1 className="PaymentTab-title">{option}</h1>
            <h2 className="PaymentTab-team">{team}</h2>
            <p className="PaymentTab-description">{parseSportVenueCompetition(description)}</p>
            <h3 className="PaymentTab-fee">
                {buildSportVenuePart(parseMatchFeeFromDescription(description),
                    `$${moneyFormatterZeros(invoiceAmount ? invoiceAmount : amount)}`)}
            </h3>
            <hr className="PaymentTab-separator"/>
        </React.Fragment>
    )
}
