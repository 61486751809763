import React, { useEffect, useState } from 'react';
import Background from '../../Payments/Background';
import { editTeamRegistration, teamTableStatus } from '../util/_options';

export default function TeamsTable({
  registrationsList,
  title,
  status,
  setCheckedRegistrations,
  onOptionClick,
  onDeleteTeam,
  onArrowClick
}) {
  const [checkboxList, setCheckboxList] = useState([]);

  useEffect(() => {
    setCheckedRegistrations(checkboxList)
  }, [checkboxList])

  const CheckboxAction = (e, id, team_id) => {
    const input = e.target;
    if (input.checked) {
      setCheckboxList(prev => [...prev, {team_registration_id: id, extra_team_id: team_id}]);
    } else {
      setCheckboxList(prev => prev.filter(item => item.team_registration_id !== id));
    }
  };

  return (
    <React.Fragment>
      <Background />
      {title && <div className="text-bold u-pb-2">{title}</div>}
      <table className={`ManageListing-table--${title} highlight u-w-100`}>
        <thead>
          <tr className="u-text-left u-noBorder u-noPadiing">
            {status === teamTableStatus.all && <th></th>}
            <th></th>
            <th className="text__color_blue">Team</th>
            <th className="text__color_blue">Captain</th>
            <th className="text__color_blue">
              Additional information
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {registrationsList.length
            ? registrationsList.map((team) => {
                const {
                  id,
                  email,
                  phone,
                  first_name,
                  last_name,
                  description,
                  returning_team,
                  title, 
                  team_id,
                  waiting
                } = team;
                return (
                  <tr
                    key={id}
                    className="collection-item bordered--2 ManageListing-table-row"
                  >
                    {status === teamTableStatus.all && 
                      <td className="u-pt-1 u-pb-1">
                        <input 
                          className='ManageListing-input u-pointer' 
                          type="checkbox" 
                          id="ManageListing-checkbox"
                          value={id} 
                          onChange={(e) => CheckboxAction(e, id, team_id)} 
                        />
                      </td>}
                    <td className="u-pt-1 u-pb-1 ManageListing-icon--column">
                      {returning_team && 
                        <i className="fal fa-star Icon Icon--noBorder ManageListing-icon--pencil u-mr-1"></i>}
                      {waiting && 
                        <i className="far fa-hourglass Icon Icon--noBorder ManageListing-icon--pencil u-mr-1"></i>}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-teamtitle-column">
                      {title}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1">
                      {`${first_name} ${last_name}`} <br/>
                      {`+${phone}`} <br />
                      {email}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-big-column">
                      {description}
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      {status === teamTableStatus.new &&
                        <i 
                          className="fal fa-arrow-alt-down ManageListing-icon ManageListing-icon--arrow"
                          onClick={() => onArrowClick(id)}
                        ></i>}
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i
                        onClick={() => onOptionClick(editTeamRegistration, team)}
                        className="far fa-pencil ManageListing-icon ManageListing-icon--pencil"
                      ></i>
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i
                        onClick={() => onDeleteTeam(id)}
                        className="fal fa-times-circle ManageListing-icon ManageListing-icon--pencil"
                      ></i>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </React.Fragment>
  );
}
