import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import queryString from "query-string";
import { DebounceInput } from 'react-debounce-input';
import Spinner from '../shared/Spinner';
import SelectDefault from '../shared/SelectDefault';
import InputWithConfirmation from '../shared/InputWithConfirmation';
import { getErrorTextFromObject } from '../../helpers/utils';
import Integrations from "../Integrations";

const DEBOUNCE_TIMEOUT = 1000;

const FIELD_LABELS = {
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  time_zone: 'Timezone',
  currency_iso_code: 'Currency',
  brand_color: 'Brand Colour',
};

const LOCATION_ORIGIN = window.location.origin;

export default class SettingsBasicForm extends React.Component {
  static propTypes = {
    company: PropTypes.shape({
      brand_color: PropTypes.string,
      currency_iso_code: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      time_zone: PropTypes.string,
      website: PropTypes.string,
    }).isRequired,
  };

  state = {
    brand_color: this.props.company.brand_color || '',
    currency_iso_code: this.props.company.currency_iso_code || '',
    email: this.props.company.email || '',
    phone: this.props.company.phone
      ? `+${this.props.company.phone}`
      : '',
    time_zone: this.props.company.time_zone || '',
    website: this.props.company.website || '',

    currencies: [],
    timezones: [],
    isLoading: false,
    errorFields: [],
    isPhoneValid: true
  };

  componentDidMount() {
    Promise.all([
      axios.get('/api/system_utils/timezones'),
      axios.get('/api/system_utils/currencies'),
      axios.get('/api/settings/venues'),
      axios.get('/api/settings/sports'),
      axios.get('/api/settings/config')
    ])
      .then((data) => {
        const timezones = data[0].data.map((item) => ({
          id: item[1],
          title: item[0],
        }));
        const currencies = data[1].data.map((item) => ({
          id: item[1],
          title: item[0],
        }));
        const venues = data[2].data.map((item) => ({
          id: item.id,
          title: item.title,
        }));
        const sports = data[3].data.map((item) => ({
          id: item.id,
          title: item.title,
        }));
        const isIframeEnabled = data[4].data.f_iframe_active;

        this.setState({ 
          currencies, 
          timezones
         });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive timezones/currencies',
          classes: 'u-bg-red',
        });
      });
  }

  handleFieldChange = (e) => {
    const { id: fieldName, value: fieldValue } = e.target;

    if (fieldValue === this.state[fieldName]) return;

    const updatedFieldData = {
      [fieldName]: fieldValue,
      errorFields: this.state.errorFields.filter(
        (item) => item !== fieldName,
      ),
    };

    this.setState(updatedFieldData, () => this.handleFieldSave(fieldName),
    );
  };

  handleInputChange = (fieldId, value) => {
    if (value === this.state[fieldId]) return;

    const updatedFieldData = {
      [fieldId]: value,
    };
    this.setState(updatedFieldData, () => this.handleFieldSave(fieldId),
    );
  };

  handlePhoneChange = (phoneNumber) => {
    if (phoneNumber === this.state.phone) return;

    const updatedFieldData = {
      phone: phoneNumber,
      isPhoneValid: isValidPhoneNumber(phoneNumber),
    };

    this.setState(updatedFieldData, () => this.handleFieldSaveDebounced('phone'),
    );
  };

  handleFieldSave = (fieldName) => {
    const fieldValue = this.state[fieldName];

    if (fieldName === 'phone' && !isValidPhoneNumber(fieldValue))
      return;

    const data = {
      company: { [fieldName]: fieldValue },
    };
    this.setState({ isLoading: true });

    return axios
      .patch('/api/settings/account', data)
      .then(() => {
        this.setState({ isLoading: false });
        M.toast({
          html: `${FIELD_LABELS[fieldName]} saved`,
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        this.setState({
          errorFields: this.state.errorFields.includes(fieldName)
            ? [...this.state.errorFields]
            : [...this.state.errorFields, fieldName],
          isLoading: false,
        });
        M.toast({
          html: `Field ${
            FIELD_LABELS[fieldName]
          } not saved: <br>${getErrorTextFromObject(error)}`,
          classes: 'u-bg-red',
        });
      });
  };

  handleFieldSaveDebounced = debounce(
    this.handleFieldSave,
    DEBOUNCE_TIMEOUT,
  );

  getInputClassErrorModifier = (fieldName) => this.state.errorFields.includes(fieldName) ? 'is-invalid' : '';

  render() {
    const { 
      isLoading, 
      isPhoneValid
    } = this.state;

    return (
      <div className="Card-content u-pt-4 u-pb-5">
        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Organisation</h3>
            <br />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label htmlFor="email" className="default-form__label">
                {FIELD_LABELS.email}
              </label>
              <br />
              <DebounceInput
                id="email"
                type="email"
                placeholder="Insert email"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'email',
                )}`}
                value={this.state.email}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label htmlFor="phone" className="default-form__label">
                {FIELD_LABELS.phone}
              </label>
              <br />
              <PhoneInput
                id="phone"
                defaultCountry="AU"
                value={this.state.phone}
                onChange={this.handlePhoneChange}
                placeholder="Insert phone number here"
                displayInitialValueAsLocalNumber
                required
                className={classNames({
                  'invalid-number': !isPhoneValid,
                })}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="website"
                className="default-form__label"
              >
                {FIELD_LABELS.website}
              </label>
              <br />
              <DebounceInput
                id="website"
                type="url"
                placeholder="Insert website here"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'website',
                )}`}
                value={this.state.website}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Localisation</h3>
            <br />
          </div>
          <div className="col-6">
            <div className="u-relative">
              <Spinner
                isLoading={isLoading}
                position={{ left: -27 }}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="time_zone"
                className="default-form__label"
              >
                {FIELD_LABELS.time_zone}
              </label>
              <SelectDefault
                id="time_zone"
                nullable
                noInit
                className="browser-default default-form__input"
                items={this.state.timezones}
                onSelect={(value) => this.handleInputChange('time_zone', value)
                }
                value={this.state.time_zone}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="currency_iso_code"
                className="default-form__label"
              >
                {FIELD_LABELS.currency_iso_code}
              </label>
              <SelectDefault
                id="currency_iso_code"
                nullable
                noInit
                className="browser-default default-form__input"
                items={this.state.currencies}
                onSelect={(value) => this.handleInputChange('currency_iso_code', value)
                }
                value={this.state.currency_iso_code}
              />
            </div>
          </div>
        </div>

        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Branding</h3>
            <br />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <InputWithConfirmation
              id="brand_color"
              type="color"
              title={FIELD_LABELS.brand_color}
              placeholder="e.g. #aa55cc"
              value={this.state.brand_color}
              onSubmit={(value) => this.handleInputChange('brand_color', value)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
