import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function TeamTitle({ value, onChange }) {
  const [isTitleEditMode, setIsTitleEditMode] = useState(false);
  const [title, setTitle] = useState(value);

  const titleChangeHandler = () => {
    setIsTitleEditMode(!isTitleEditMode);
    onChange(title);
  };

  return (
    <h1 className="u-font-size-30 d-inline-flex align-items-center u-m-0 u-pb-3">
      {isTitleEditMode && (
        <input
          className="browser-default"
          id="team-title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      )}

      {!isTitleEditMode && title}

      <span
        onClick={titleChangeHandler}
        className="table__action d-flex u-pl-1"
      >
        <i
          className="u-font-size-12 far fa-pen"
          aria-label="edit team name"
        />
      </span>
    </h1>
  );
}

TeamTitle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
