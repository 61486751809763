import React from "react";
import PropTypes from "prop-types"
import {moneyFormatterZeros} from "../../lib/utils"

export default function AvailableBalance({activated, activationLink, balance, payoutsLink, detailsLink}) {
    
    const availableBalance = balance ? moneyFormatterZeros(balance) : 0

    return (
        <div className="AvailableBalance">
            <span className="AvailableBalance-title">Available Balance</span>
            <div className="AvailableBalance-block">
                <span className="AvailableBalance-amount">${availableBalance}</span>
                {!activated ? 
                    <a
                        className="Button Button--medium AvailableBalance-button"
                        href={activationLink}
                    >
                        Activate Account
                    </a> :
                    <div style={{display: 'flex'}}>
                    <a 
                        className="Button Button--medium AvailableBalance-button"
                        href={payoutsLink}
                    >
                        View Payouts
                    </a>
                    <a 
                        className="Button Button--medium Button--switchLast AvailableBalance-button"
                        href={detailsLink}
                    >           
                        Account Details
                    </a>
                    </div>
                } 
            </div>
        </div>
    ) 

}

AvailableBalance.propTypes = {
    activated: PropTypes.bool.isRequired,
    activationLink: PropTypes.string.isRequired,
    balance: PropTypes.number,
    payoutsLink: PropTypes.string,
    detailsLink: PropTypes.string
}
