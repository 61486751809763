import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SCORE_STATUS } from './constants';
import ModalClosableOutside from '../shared/ModalClosableOutside';

export default class ScoreStatusDropdown extends Component {
  static propTypes = {
    matchScoreStatus: PropTypes.string,
    onScoreStatusUpdate: PropTypes.func.isRequired,
    teamNames: PropTypes.shape({
      teamA: PropTypes.string.isRequired,
      teamB: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    matchScoreStatus: SCORE_STATUS[0],
  };

  state = {
    isOpened: false,
  };

  toggleDropDown = (e) => {
    this.setState({ isOpened: !this.state.isOpened });
    return e && e.preventDefault();
  };

  handleListClick = (e) => {
    const value = parseInt(e.target.value, 10);

    this.props.onScoreStatusUpdate(SCORE_STATUS[value]);
    this.toggleDropDown();
  };

  handleHidePopup = () => {
    this.setState({ isOpened: false });
  };

  render() {
    const { matchScoreStatus, teamNames } = this.props;
    const { isOpened } = this.state;

    return (
      <ModalClosableOutside
        isModalWindowMode={false}
        onHideModal={this.handleHidePopup}
      >
        <span className="score-status-more-button">
          <a
            onClick={this.toggleDropDown}
            className="table__action u-color-emerald"
            href="#"
          >
            <i className="far fa-ellipsis-h u-font-size-21" />
          </a>

          {isOpened && (
            <span className="popup__wrapper popup__wrapper--score-status-popup">
              <ul>
                <li
                  onClick={this.handleListClick}
                  className={
                    matchScoreStatus === SCORE_STATUS[1]
                      ? 'selected'
                      : ''
                  }
                  value={1}
                >
                  {teamNames.teamA} forfeited
                </li>
                <li
                  onClick={this.handleListClick}
                  className={
                    matchScoreStatus === SCORE_STATUS[2]
                      ? 'selected'
                      : ''
                  }
                  value={2}
                >
                  {teamNames.teamB} forfeited
                </li>
                <li
                  onClick={this.handleListClick}
                  className={
                    matchScoreStatus === SCORE_STATUS[3]
                      ? 'selected'
                      : ''
                  }
                  value={3}
                >
                  Omit match results
                </li>
                {SCORE_STATUS.isStatusSet(matchScoreStatus) && (
                  <li
                    onClick={this.handleListClick}
                    value={0}
                    className="top-bordered"
                  >
                    Clear result
                  </li>
                )}
              </ul>
            </span>
          )}
        </span>
      </ModalClosableOutside>
    );
  }
}
