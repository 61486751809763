import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Calendar from '../shared/Calendar';
import Step from '../FixturesForm/Step';
import TextInput from '../shared/TextInput';
import Button from '../shared/Button';
import { dateRange } from '../../lib/dateTimeHelper';
import { redirect } from '../../lib/utils';
import Switch from '../shared/Switch';
import SlotsCount from '../FixturesForm/SlotsCount';
import TimeSlots from '../FixturesForm/TimeSlots';
import { create, get, destroy } from '../../lib/API';

const SWITCH_VALUES = ['Update', 'Extend'];
const nonPlayingDates = (dates) => (dates && dates.split(',').map((d) => moment(d, 'DD/MM/YYYY')))
  || [];
const excludeDates = (dates) => dates.map((d) => d.format('DD/MM/YYYY')).join(',');

export default class FixturesForm extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    fixture: PropTypes.object,
    matches_params: PropTypes.object,
  };

  state = {
    league_id: this.props.league.id,
    from_round: 0,
    start_date: moment(this.props.fixture.start_date),
    new_rounds: null,
    exclude_dates: [],
    action: 'Extend',
    slots_number: ~~(this.props.teams.length / 2),
    non_playing_dates: nonPlayingDates(
      this.props.fixture.non_playing_dates,
    ),
    sport_duration: this.props.sport_duration,
    timeslots_data: {},
    loadedCourses: 0,
    newSlots: [],
    selected: [],
    slotsReady: false,
  };

  setLoadedTimeslots = (timeslots) => {
    this.setState({ selected: timeslots });
  };

  get dateSet() {
    const { exclude_dates } = this.state;

    return dateRange(exclude_dates).map((d, i) => (
      <div className="step__value_item" key={i}>
        {d}
      </div>
    ));
  }

  goBack = () => {
    const { league, fixture } = this.props;
    destroy({}, `/fixtures/${fixture.id}/mass_timeslots`, () => redirect(`/leagues/${league.id}`),
    );
  };

  validateStep1 = () => {
    const { league, wizard } = this.props;
    const { new_rounds, exclude_dates } = this.state;
    const dates = excludeDates(exclude_dates);

    const data = {
      [wizard]: { new_rounds, exclude_dates: dates },
      league_id: league.id,
      current_step: 'step1',
    };

    create(
      data,
      `/leagues/${league.id}/${wizard}/validate_step`,
      this.loadSlots,
      this.loadSlots,
    );
  };

  loadSlots = () => {
    this.setState({ slotsReady: true });
  };

  submit = () => {
    const { league, wizard } = this.props;
    const { new_rounds, exclude_dates } = this.state;
    const dates = excludeDates(exclude_dates);

    const data = {
      [wizard]: { new_rounds, exclude_dates: dates },
      league_id: league.id,
      current_step: 'step1',
    };

    create(
      data,
      `/leagues/${league.id}/${wizard}/validate_step`,
      this.afterSubmit,
      this.afterSubmit,
    );
  };

  afterSubmit = () => {
    const { league, wizard } = this.props;
    const { new_rounds, exclude_dates } = this.state;
    const dates = excludeDates(exclude_dates);

    redirect(
      `/leagues/${league.id}/${wizard}/step2`
        + `?${wizard}[new_rounds]=${new_rounds}`
        + `&${wizard}[exclude_dates]=${dates}`,
    );
  };

  handleSwitch = (action) => {
    if (action === 'Update') {
      redirect(
        `/leagues/${this.props.league.id}/modify_fixture_wizard/step1`,
      );
    }
  };

  handleSelectDates = (exclude_dates) => {
    this.setState({ exclude_dates }, this.validateStep1);
  };

  render() {
    const { courses, fixture, sport_duration } = this.props;
    const {
      selected,
      start_date,
      new_rounds,
      exclude_dates,
      action,
      slots_number,
      slotsReady,
    } = this.state;

    const selectedCount = selected.length;
    const isRoundAutoCollapse =      typeof new_rounds === 'string' && new_rounds.length > 1;

    return (
      <div className="bg-white">
        <Step
          number="1"
          autoCollapse
          title="Type"
          value={new_rounds && action}
          completed={() => new_rounds}
        >
          <Switch
            values={SWITCH_VALUES}
            value="Extend"
            id="switch"
            onSelect={this.handleSwitch}
          />
        </Step>
        <Step
          number="2"
          autoCollapse={isRoundAutoCollapse}
          title="New rounds"
          value={new_rounds}
        >
          <TextInput
            mod="text-input__input_small"
            title="New rounds"
            id="rounds_num"
            validate={['required']}
            type="number"
            onChange={(new_rounds) => {
              if (typeof new_rounds === 'string') {
                new_rounds.length > 1
                  ? this.setState({ new_rounds }, () => this.validateStep1(),
                    )
                  : this.setState({ new_rounds });
              }
            }}
            onBlur={({ target: { value: new_rounds } }) => {
              if (typeof new_rounds === 'string') {
                this.setState({ new_rounds }, () => this.validateStep1(),
                );
              }
            }}
            value={new_rounds}
          />
        </Step>
        <Step
          number="3"
          disabled={!new_rounds}
          title="Non-playing dates"
          value={this.dateSet}
        >
          <Calendar
            multiSelect
            mod="calendar-red-select"
            value={exclude_dates}
            blockedBefore={start_date}
            onSelect={this.handleSelectDates}
          />
        </Step>
        {slotsReady && (
          <Step
            number="4"
            title="Timeslots"
            completed={() => selectedCount === slots_number}
            value={selected.map((s) => s.start_time).join(', ')}
          >
            <div>
              <SlotsCount
                current={selectedCount}
                required={slots_number}
              />
              <TimeSlots
                loadTimeslots={this.setLoadedTimeslots}
                isAllowAdd={selectedCount < slots_number}
                fixtureId={fixture.id}
                courses={courses}
                numberModifiableRounds={new_rounds}
                wizard="extend"
                sport_duration={sport_duration}
                onChangeSelected={(selected) => this.setState({ selected })
                }
              />
            </div>
          </Step>
        )}
        <div className="d-flex u-p-4">
          <Button
            mod="Button--primary u-mr-1"
            title="See preview"
            disabled={selectedCount !== slots_number}
            id="create_button"
            onClick={this.submit}
          />
          <Button
            mod="Button--cancel"
            title="Cancel"
            id="cancel_button"
            onClick={this.goBack}
          />
        </div>
      </div>
    );
  }
}
