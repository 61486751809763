import PropTypes from 'prop-types';
import React from 'react';
import { orderBy } from 'lodash';
import axios from 'axios';
import { Col, Row } from '../shared/Grid';
import League from './League';
import Teams from './Teams';
import Button from '../shared/Button';
import { isValid } from '../../lib/validation';
import { leagueDTO } from '../../lib/DTO';
import { buildQueryParamsArrayFormat } from '../ManageListing/util/_build_request_params';
import { redirect } from '../../lib/utils';
import Background from '../ManageListing/Background';
import ConfirmOnceOffFees from './ConfirmOnceOffFees';

const REQUIRED_FIELDS = [
  'fee',
  'sport_id',
  'venue_id',
  'grade_id',
  'season_id',
  'division_id',
];

export default class LeaguesForm extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
    seasons: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    venues: PropTypes.array.isRequired,
    team_registration_ids: PropTypes.array,
    extra_team_ids: PropTypes.array.isRequired,
    extra_teams: PropTypes.array.isRequired,
    day: PropTypes.string,
    venue_id: PropTypes.number,
    division_id: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      league: {
        ...props.league,
        day: props.day || props.league.day,
        division_id: props.division_id || props.league.division_id,
        venue_id: props.venue_id || props.league.venue_id
      },
      teams: orderBy(props.league.teams, ['title']),
      team_ids: props.team_ids,
      once_off_fees: props.league.once_off_fees,
      back_url: props.back_url,
      currencySymbol: '',
      extra_team_ids: props.extra_team_ids,
      extra_teams: props.extra_teams,
      popup_fees: null
    };
  }

  componentDidMount() {

    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          currencySymbol: response.data.currency_symbol,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  }

  get isNew() {
    const {
      league: { id },
    } = this.props;

    return id === null;
  }

  isLeagueValid() {
    const { league } = this.state;

    return (
      REQUIRED_FIELDS.every((key) => !!league[key])
      && isValid(+league.fee, ['float', 'positive'])
    );
  }

  submit(onceoffFees) {    
    const { team_registration_ids } = this.props;
    const { team_ids, extra_team_ids, league } = this.state;
    const all_team_ids = team_ids.concat(extra_team_ids);

    let once_off_fees = [];
    let old_once_off_ids = [];

    const NewOnceOffFeesDTO = (fee) => ({
      amount: fee.amount,
      description: fee.description,
      due_date: fee.due_date,
      team_ids: fee.team_ids
    });

    if (onceoffFees) {
      onceoffFees.forEach(item => {
        if (item.created_at) {
          let newItem = {
            id: item.id,
            team_ids: item.team_ids
          }
          old_once_off_ids.push(newItem)
        } else {
          once_off_fees.push(NewOnceOffFeesDTO(item))
        }
      })
    }


    const data = { 
      league: leagueDTO(league, all_team_ids, once_off_fees, []), 
      team_registration_ids  
    };

    const body = {
      once_off_ids: old_once_off_ids
    };
    
    if (this.isNew) {
      axios
        .post(`/api/leagues?${buildQueryParamsArrayFormat(data)}`)
        .then(response => {
          if (response.status === 200) {
            const id = response.data.league.id;
            redirect(`/leagues/${id}`)
          } else if (response.status === 422) {
            console.error(response.data.message)
          }
        })
        .catch(error => console.error(error))

    } else {
      axios
        .patch(`/api/leagues/${league.id}?${buildQueryParamsArrayFormat(data)}`, body)
        .then(response => {
          if (response.status === 200) {
            redirect(`/leagues/${league.id}`)
          } else if (response.status === 422) {
            console.error(response.data.message)
          }
        })
        .catch(error => console.error(error))

    }
  }

  onTeamRemove = (team) => {
    const { teams, team_ids, extra_team_ids, extra_teams, once_off_fees } = this.state;

    
    once_off_fees.forEach(onceoff => {
      if (onceoff.oldteam_ids) {
        onceoff.oldteam_ids = onceoff.oldteam_ids.filter(item => item !== team.id)
      } 
      if (onceoff.team_ids) {
        onceoff.team_ids = onceoff.team_ids.filter(item => item !== team.id)
      }
    });  
    
    this.setState({
      teams: teams.filter((item) => item.id !== team.id),
      team_ids: team_ids.filter((team_id) => team_id !== team.id),
      extra_teams: extra_teams.filter((item) => item.id !== team.id),
      extra_team_ids: extra_team_ids.filter((team_id) => team_id !== team.id),
      once_off_fees: once_off_fees
    });
  };

  handleAddingTeam(team) {
    const { teams, team_ids, once_off_fees } = this.state;

    let onceOffFees = once_off_fees

    onceOffFees.forEach(item => {
      if (item.team_ids) {
        item.team_ids.push(team.id)
      } 
      else {
        item.team_ids = [];
        item.team_ids.push(team.id);
      }
    });

    teams.push(team)
    team_ids.push(team.id)


    this.setState({
      teams,
      team_ids,
      once_off_fees: onceOffFees
    });
  }

  openModal = () => {
    const { once_off_fees } = this.state

    if (once_off_fees.length) {
      this.setState({
        popup_fees: once_off_fees
      })
    } else {
      this.submit(null)
    }
  }

  disablePopup = () => {

    this.setState({
      popup_fees: null
    })
  }

  render() {
    const {
      sports,
      venues,
      divisions,
      grades,
      seasons,
      league: { days },
    } = this.props;
    const {
      teams,
      league,
      team_ids,
      once_off_fees,
      back_url,
      currencySymbol,
      extra_team_ids,
      extra_teams,
      popup_fees
    } = this.state;

    return (
      <div>
        <Background isShow={popup_fees}
            setDisable={this.disablePopup}
            backgroundStyle={'Underlayer-dark'}></Background>

        {popup_fees && 
          <ConfirmOnceOffFees extraTeams={extra_teams}
                              teams={teams}
                              extraTeamIds={extra_team_ids}
                              teamIds={team_ids}
                              onceOffFees={once_off_fees}
                              confirmCallback={(onceoffFees) => this.submit(onceoffFees)}
                              cancelCallback={this.disablePopup}
                            ></ConfirmOnceOffFees>
        }

        <Row>
          <Col l="5">
            <League
              league={league}
              divisions={divisions}
              grades={grades}
              once_off_fees={once_off_fees}
              seasons={seasons}
              sports={sports}
              venues={venues}
              days={days}
              team_ids={team_ids}
              extra_team_ids={extra_team_ids}
              onChange={(league) => {
                this.setState({
                  league,
                  once_off_fees: [
                    ...league.once_off_fees
                  ],
                });
              }}
              currencySymbol={currencySymbol}
            />
          </Col>
          <Col l="1" />
          <Col l="6">
            <Teams
              teams={teams.concat(extra_teams)}
              team_ids={team_ids.concat(extra_team_ids)}
              onChange={(team) => this.handleAddingTeam(team)}
              onRemove={(team) => this.onTeamRemove(team)}
            />
          </Col>
          <Col l="12" mod="u-pt-2 u-mb-1">
            <div className="d-flex">
              <Button
                mod="Button--primary u-mr-1"
                title={`${this.isNew ? 'Create' : 'Update'} league`}
                disabled={!this.isLeagueValid()}
                id="create_button"
                onClick={() => this.openModal()}
              />
              <Button
                mod="Button--cancel"
                title="Cancel"
                id="cancel_button"
                onClick={() => redirect(back_url)}
              />
            </div>
          </Col>
        </Row>

      </div>
    );
  }
}
