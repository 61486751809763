import React, {useState, useEffect} from 'react'
import moment from "moment";
import DropdownList from "../DropdownList";
import {tabs} from "../Index";
import Background from "../Background";
import {paid} from "../util/_tabs";
import {statuses} from "../util/_statuses";
import {editFee, editPayment} from "../util/_options";
import { moneyFormatterZeros } from '../../../lib/utils';

export default function PaymentsTable({paymentsList, chosenTab, setPayment, setOption, setPage, teamId, children}) {

    const [paymentIdDropdownList, setPaymentIdDropdownList] = useState(null);
    const [downloadingNewPage, setDownloadingNewPage] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            if (!setPage()) {
                setDownloadingNewPage(false);
            }
        }
    }

    const onPaymentClick = (payment) => {
        const {id, manual, status} = payment;
        if (manual) {
            const option = statuses[status] === 'Active' ? editFee : editPayment;
            onOptionClick(payment)(option)
        } else {
            setPaymentIdDropdownList(id)
        }
    }

    const onOptionClick = (payment) => (option) => {
        setPaymentIdDropdownList(null);
        setPayment(payment);
        setOption(option);
    }

    const theadClassNames = 'u-pt-2 u-pb-2';

    return (
        <React.Fragment>
            <Background setDisable={() => setPaymentIdDropdownList(null)}
                        isShow={paymentIdDropdownList !== null}/>

            <table className="bordered highlight u-w-100">
                <thead>
                <tr className="u-text-left u-noBorder">
                    <th className={theadClassNames}>Date</th>
                    <th className={theadClassNames}>Amount</th>

                    {children}

                    <th className={theadClassNames}>Description</th>
                    <th className={theadClassNames}/>
                    <th className={theadClassNames}/>
                </tr>
                </thead>
                <tbody>
                {paymentsList ? paymentsList.map(payment => {
                        const {
                            id,
                            due_date: dueDate,
                            amount,
                            team,
                            sport,
                            venue,
                            description,
                            status,
                            paid_at: paidAt,
                            manual
                        } = payment;
                        const correctDate = tabs[chosenTab].tab === paid ? paidAt : dueDate;
                        return (
                            <tr className="collection-item"
                                key={id}
                            >
                                <td>{moment(correctDate).format('LL')}</td>
                                <td>${moneyFormatterZeros(amount)}</td>
                                {tabs[chosenTab].tab === paid ?
                                    <td className="PaymentsTable-status-column">{statuses[status]}</td> : null}
                                {teamId ? null : <td>{team}</td>}
                                <td>{sport}</td>
                                <td>{venue}</td>
                                <td>{description}</td>
                                <td onClick={() => onPaymentClick(payment)}>
                                    {manual ?
                                        <i className="far fa-pen adjust-fee-schedule-tab-icon u-pointer"/> :
                                        <i className="fas fa-chevron-down PaymentsTable-arrow u-pointer"> </i>
                                    }
                                </td>
                                <td>{paymentIdDropdownList === id ?
                                    <DropdownList tab={tabs[chosenTab].tab}
                                                  onOptionClick={onOptionClick(payment)}
                                                  status={status}
                                    /> : null}
                                </td>
                            </tr>
                        )
                    })
                    : null}
                </tbody>
            </table>
            {downloadingNewPage && paymentsList.length >= 20 ?
                <p className="u-text-center">Fetching more payments...</p> : null}
        </React.Fragment>
    )
}
