import { pickBy, identity } from 'lodash';

export const fullName = (user) => `${(user && user.first_name && `${user.first_name} `) || ''}${
    user && user.last_name
  }`;

export const redirect = (url) => window.location.replace(url);

export const isPartialEqual = (obj1, obj2, keys) => obj1 && obj2 && keys.every((k) => `${obj1[k]}` === `${obj2[k]}`);

export const findById = (obj, id, def = {}) => Object.keys(obj)
    .map((key) => obj[key])
    .find((v) => +v.id === +id) || def;

export const cleanup = (obj) => pickBy(obj, identity);

export const toggleKey = (key) => (state) => ({
  [key]: !state[key],
});

export const snakeToCamel = (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''),
  );

export const handleResponseErrorFormat = (error) => Object.entries(error.response.data).reduce((acc, err) => {
    acc[snakeToCamel(err[0])] = err[1].join(' ');
    return acc;
  }, {});

export const isEmailValid = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const toast = (responseMessage, type, defaultMessage) => {
  const message = responseMessage ? responseMessage : defaultMessage
  let toastClass;

  switch (type) {
    case 'success':
      toastClass = 'u-bg-emerald';
      break;
    case 'fail':
      toastClass = 'u-bg-red';
      break;
    default:
      '';
  }

  return M.toast({
      html: message,
      classes: toastClass,
  })
  
}

export const moneyFormatterZeros = (value) => {
  value = parseFloat(value);
  return (Math.ceil(value) - value) === 0 ? value.toFixed(0) : value.toFixed(2)
}

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export const jsonToFormData = (data) => {
  const formData = new FormData();
  
  buildFormData(formData, data);
  
  return formData;
}
