import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDataApi from '../../helpers/useDataApi';
import classNames from 'classnames';

export default function LeaguesList({ teamId }) {
  const [
    { data: leagues, isLoading, isError },
    fetchLeagues,
  ] = useDataApi([]);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    fetchLeagues({
      url: `/api/leagues`,
      params: {
        'filters[team_id]': teamId,
        'filters[active]': isActive,
      },
    });
  }, [fetchLeagues, isActive, teamId]);

  function noLeaguesContent() {
    let message = ['The team has no']

    if (!isActive) {
      message.push('past')
    }

    message.push('leagues yet.')

    return message.join(' ')
  }

  return (
    <div className="u-mt-3">
      <div className="table__head_wrapper">
        <button

          type='button'
          onClick={() => setIsActive(true)}
          className={classNames('Button Button--clean u-weight-500 u-pr-3 u-font-size-12', {'Button--dim': !isActive })}
        >
          Active Leagues
        </button>

        <button
          type='button'
          onClick={() => setIsActive(false)}
          className={classNames('Button Button--clean u-weight-500 u-font-size-12', {'Button--dim': isActive })}
        >
          Past Leagues
        </button>
      </div>
      {!isLoading &&
        (isError || !Array.isArray(leagues) || !leagues.length) && (
          <div className="u-pb-2 u-pt-2">
            {noLeaguesContent()}
          </div>
        )}
      {isLoading && <div className="u-pb-2 u-pt-2">Loading...</div>}
      {!isLoading && (
        <ul className="list">
          {leagues &&
            leagues.map((league) => (
              <li
                className="list-item u-font-size-12"
                key={league.id}
              >
                <a href={`/leagues/${league.id}`}>{league.summary}</a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

LeaguesList.propTypes = {
  teamId: PropTypes.number.isRequired,
};
